import React from 'react';

import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { useTranslation } from 'react-i18next';

export const IncompatibilityDeviceScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="root">
      <Header />

      <div id="main" className="main">
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                paddingTop: 15
              }}
            >
              <img
                width={'100%'}
                src={require('../assets/deviceIncompatibility.svg')}
              />
            </div>

            <h2 style={{ textAlign: 'center' }}>
              {t('Sorry! Your device is not compatible with Nect.')}
            </h2>
            <div
              style={{
                alignItems: 'left',
                fontSize: 13
              }}
            >
              <span> {t('You can')}:</span>
              <ol style={{ paddingLeft: 15 }}>
                <li>{t('Try it on a different mobile device')}</li>
                <li>
                  {t(
                    'Update your mobile’s Operation System to iOS 10.3 or later, Android 6.0 or later'
                  )}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
