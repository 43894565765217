import 'core-js/stable';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { postEvent } from './api/postEvent';
import { getItem } from './helper/storage';
import { extractUrlParam } from './helper/extractUrlParam';

require('./lang/index');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

global.delay = n => new Promise(r => setTimeout(r(), n));

/* figuring out, if the user has access to the local storage.
 * will throw an exception on access, if so
 */
try {
  const n = window.localStorage.length;
} catch (e) {
  const { search } = window.location;

  const uuid = extractUrlParam({ url: search, type: 'uuid' });

  postEvent({ uuid, event: 'WEB.JUMP.NO_LOCALSTORAGE' });
}

/**
 * user joined page
 */
window.onfocus = e => {
  const uuid = getItem('uuid') || '';

  postEvent({
    uuid,
    event: 'WEB.JUMP.ONFOCUS'
  });
};

/**
 * user left page
 */
window.onblur = () => {
  const uuid = getItem('uuid') || '';

  postEvent({
    uuid,
    event: 'WEB.JUMP.ONBLUR'
  });
};

document.onvisibilitychange = () => {
  const uuid = getItem('uuid') || '';

  postEvent({
    uuid,
    event: 'WEB.JUMP.ONVISIBILITYCHANGE',
    details: {
      documentHidden: document.hidden
    }
  });
};

window.onerror = error => {
  const uuid = getItem('uuid') || '';

  postEvent({
    uuid,
    event: 'WEB.JUMP.ERROR',
    details: {
      error: error.message || error,
      stack: error.stack
    }
  });

  console.error({ error });
};
