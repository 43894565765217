import { useLocation } from '../react-router-hooks';
import { extractUrlParam } from '../helper/extractUrlParam';
import { getItem, setItem } from '../helper/storage';
import { useEffect, useState } from 'react';

export const useLoadedFromApp = () => {
  const { location } = useLocation();
  const [isLoadedFromApp, setIsLoadedFromApp] = useState(false);

  useEffect(() => {
    if (
      !isLoadedFromApp &&
      (extractUrlParam({
        url: location.search,
        type: 'appVersion'
      }) ||
        getItem('isLoadedFromApp') === 'true')
    ) {
      setItem('isLoadedFromApp', 'true');
      setIsLoadedFromApp(true);
    }
  }, [location.search]);

  return isLoadedFromApp;
};
