/**
 * some phone may have a missing device type.
 * see https://www.zytrax.com/tech/web/mobile_ids.html for examples
 */
export function isAndroid() {
  return /android|Linux|X11/i.test(navigator.userAgent);
}

export const getDeviceType = (): OSType | null => {
  if (isAndroid()) {
    return 'android';
  } else if (isIOS()) {
    return 'ios';
  }

  return null;
};

export function isIOS() {
  return /iPad|iPhone|iPod/i.test(navigator.userAgent) || isIpadOS();
}

export function isIpadOS() {
  return (
    /Macintosh;/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1
  );
}

export function isSupportedIdentDevice() {
  return isAndroid() || isIOS();
}

export function isFirefox() {
  return /firefox/i.test(navigator.userAgent);
}

export const isMobile = () =>
  /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera\ mini|avantgo|mobilesafari|docomo)/gim.test(
    navigator.userAgent
  );

export const isTablet = () =>
  /(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/gim.test(
    navigator.userAgent
  );
