import React from 'react';

import styles from './StoreBadge.module.css';
import { useWideScreen } from '../../hooks/useWide';
import { getDeviceType, isFirefox } from '../../helper/device';
import { changeShowStartButton, useStateValue } from '../../hooks/useState';
import { useDeepStartLink } from '../../hooks/useDeepStartLink';
import { useTranslation } from 'react-i18next';
import { postEvent } from '../../api/postEvent';
import { useGetInstalledRelatedApps } from '../../hooks/useGetInstalledRelatedApps';

interface Props {
  style?: any;
  target?: string;
  ownButton?: boolean;
  type: OSType;
  href?: string;
  storeLink?: string;
  onClick?: () => void;
  onBackground?: 'white' | 'black' | 'header';
  isFormHelp?: boolean;
  isFormFeedback?: boolean;
}

export const StoreBadgeMobileNew = ({
  style,
  type,
  onBackground = 'black',
  ownButton,
  isFormHelp,
  isFormFeedback,
  target = '_blank'
}: Props) => {
  const isWideScreen = useWideScreen();
  const installedRelatedApps = useGetInstalledRelatedApps();

  const { t } = useTranslation();
  const [{ responseURI, uuid, showStartButton }, dispatch] = useStateValue();

  const shouldShowStartButton =
    (showStartButton && getDeviceType() === 'ios') ||
    installedRelatedApps?.length;

  if (type === 'depending') {
    type = getDeviceType();
  }

  const {
    directLink,
    storeLink,
    copyMagic,
    onBadgeClick,
    deepLink
  } = useDeepStartLink({
    uuid,
    type,
    responseURI,
    isMobile: !isWideScreen
  });

  let link = storeLink;
  let src = '';

  if (type === 'android') {
    src = require('../../assets/store-button/google-play-badge-' +
      t('langCode') +
      '.png');
    if (!isFirefox() && shouldShowStartButton && !isWideScreen) {
      link = deepLink;
    }
  } else {
    src = require('../../assets/store-button/app-store-badge-' +
      t('langCode') +
      '.png');
    if (shouldShowStartButton && !isWideScreen) {
      link = directLink;
    }
  }
  if (isFormHelp || isFormFeedback) {
    return null;
  }

  if (ownButton) {
    return (
      <a
        href={link}
        target={'_blank'}
        rel="noopener noreferrer"
        className={styles.button}
        style={{
          ...style,
          background:
            'radial-gradient(100% 284.05% at 0% 50%, #34384B 0%, #0E1528 100%)',
          borderRadius: '4px',
          fontSize: '16px',
          fontWeight: 500,
          padding: '10px 50px'
        }}
        onClick={e => {
          // if (!uuid) {
          //   e.preventDefault();
          //   return;
          // }

          copyMagic();

          postEvent({
            uuid,
            event: 'WEB.JUMP.APP',
            details: {
              directLink,
              type,
              isWideScreen,
              storeLink
            }
          });

          postEvent({
            uuid,
            event:
              onBackground !== 'header'
                ? 'WEB.JUMP.ACTION.MOBILE.BODYCTA'
                : 'WEB.JUMP.ACTION.MOBILE.HEADERCTA',
            details: {
              directLink,
              storeLink,
              type,
              isWideScreen,
              pathname: window.location.pathname
            }
          });

          if (!shouldShowStartButton) {
            dispatch(changeShowStartButton({ showStartButton: true }));
            onBadgeClick();
            e.preventDefault();
          }
        }}
      >
        {shouldShowStartButton
          ? t('Open Nect Wallet')
          : t('Download Nect Wallet')}
      </a>
    );
  }

  return (
    <a
      href={link}
      target={target}
      className={styles.badge}
      onClick={() => {
        copyMagic();

        postEvent({
          uuid,
          event: 'WEB.JUMP.APP',
          details: {
            directLink,
            storeLink,
            type,
            isWideScreen
          }
        });

        postEvent({
          uuid,
          event:
            onBackground !== 'header'
              ? 'WEB.JUMP.ACTION.MOBILE.BODYCTA'
              : 'WEB.JUMP.ACTION.MOBILE.HEADERCTA',
          details: {
            directLink,
            storeLink,
            type,
            isWideScreen,
            pathname: window.location.pathname
          }
        });

        dispatch(changeShowStartButton({ showStartButton: true }));
      }}
    >
      {onBackground !== 'header' && (
        <img style={{ width: '100%', height: '100%' }} src={src} />
      )}
      {!isFormFeedback &&
        !isFormHelp &&
        onBackground === 'header' &&
        t('Download')}
    </a>
  );
};
