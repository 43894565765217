// react-router-hooks.js

import { useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';

export function useRouter() {
  return useContext(RouterContext);
}

export function useParams() {
  const { match } = useRouter();
  return match.params;
}

export function useLocation() {
  const { location } = useRouter();

  function navigate(to, { replace = false } = {}) {
    if (location.pathname === to) {
      return;
    }

    window.location.href = to;
  }

  return {
    location,
    navigate
  };
}
