import { theme } from '../../theme';

export const smsSelectStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: 350,
    padding: '6px 16px',
    marginTop: 8,
    marginLeft: -1,
    borderBottom: state.selectProps.borderBottom,
    color: state.selectProps.menuColor
  }),
  menuList: provided => ({
    ...provided,
    height: 250
  }),
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      paddingLeft: 0,
      marginRight: 0,
      color: theme.black,
      '&:hover': {
        color: theme.black
      }
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F0F1F2' : 'inherit',
    color: '#4B4F55',
    padding: 8,
    margin: '8px 0',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: state.isSelected ? '#F0F1F2' : '#FAFBFC'
    },
    '&:active': {
      backgroundColor: '#F0F1F2'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  control: (provided: any, state: any) => ({
    width: 105,
    paddingRight: 0,
    display: 'flex',
    border: state.isFocused
      ? '1px solid #0A0D1B'
      : state.selectProps.smsSent
      ? '1px solid #11C6B0'
      : '1px solid #dddee0',
    borderRadius: 4,
    marginRight: 8,
    height: 40,
    '&:hover': {
      borderColor: state.selectProps.smsSent ? '#11C6B0' : '#797C81'
    }
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, padding: 0 };
  }
};
