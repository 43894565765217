import React from 'react';

import styles from './DescriptionButton.module.css';

import { ButtonProps, Button } from '../Button/Button';

interface Props {
  buttonProps: ButtonProps;
  topText?: string;
  bottomText?: any;
}

export const DescriptionButton = ({
  buttonProps,
  topText,
  bottomText
}: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.text}>{topText}</span>
      <Button {...buttonProps} />
      <span className={styles.text}>{bottomText}</span>
    </div>
  );
};
