import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DescriptionBox } from '../components/DescriptionBox/DescriptionBox';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { SMS } from '../components/smsNew/SMS';
import { routes } from '../constants/constants';
import { useLocationPrepare } from '../hooks/useLocationPrepare';
import { useStateValue } from '../hooks/useState';
import { theme } from '../theme';
import { TuevBadge } from '../components/tuev/TuevBadge';

const Image = styled.img`
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 70%;
  @media (max-width: 1500px) {
    justify-content: flex-end;
  }
`;

export const RightImageContainer = styled.div`
  @media (max-width: 1500px) {
    width: auto;
  }
  width: 45vw;
  max-width: 506px;
`;

export const IFrameScreen = () => {
  useLocationPrepare();

  const { t } = useTranslation();
  const [{ uuid, responseURI }] = useStateValue();

  return (
    <div className="root">
      <Header isIFrame />

      <div id="main" className="main" style={{ display: 'flex' }}>
        <div
          style={{
            paddingTop: 100,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <DescriptionBox
              title={t('Get the Nect app and start your identification.')}
              textElement={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 640
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      lineHeight: '150%',
                      letterSpacing: '0.04em'
                    }}
                  >
                    {t(
                      'Get the Nect app by sending an SMS link to your smartphone. Nect app is available on both iPhone and Android phone.'
                    )}
                  </p>
                  <SMS />
                </div>
              }
            />
            <div
              style={{ marginTop: 20, display: 'flex', alignItems: 'flex-end' }}
            >
              <div>
                <Link
                  style={{ color: theme.grey, textDecoration: 'underline' }}
                  to={{
                    pathname: routes.QR,
                    state: { isIFrame: true, prevRoute: routes.IFRAME }
                  }}
                >
                  {t('I prefer to get the Nect app myself')}
                </Link>
              </div>
            </div>
          </div>

          <RightContainer>
            <RightImageContainer>
              <Image
                alt="Start Identification via SMS"
                src={require('../assets/sms-signup.png')}
              />
            </RightImageContainer>
          </RightContainer>
        </div>
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            alignItems: 'flex-end',
            flex: 1,
            justifyContent: 'flex-end'
          }}
        >
          <TuevBadge />
        </div>
      </div>
      <Footer isIFrame />
    </div>
  );
};
