import React from 'react';

import styles from './Footer.module.css';
import { useWideScreen } from '../../hooks/useWide';
import { useTranslation } from 'react-i18next';
import { postEvent } from 'src/api/postEvent';
import { useStateValue } from '../../hooks/useState';
import { Ellipse } from './Ellipse';
import {
  links,
  getPrivacyLink,
  getImprintLink,
  getLegalLink
} from '../../constants/constants';

interface Props {
  isIFrame?: boolean;
}

interface Link {
  text: string;
  href: string;
  onClick?: any;
  isMobile: boolean;
}

const Link = ({ text, href, isMobile, onClick }: Link) => (
  <a
    className={isMobile ? styles.aMobile : ''}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
    href={href}
  >
    {text}
  </a>
);

export const Footer = (props: Props) => {
  const { t } = useTranslation();

  const [{ uuid = '' }, dispatch] = useStateValue();

  const isWideScreen = useWideScreen();

  if (!isWideScreen) {
    return null;
  }

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footerBlock}>
        <span className={styles.footerCaseUUID}>
          {uuid && `Case-ID: ${uuid.substring(0, 8)}`}
        </span>
      </div>
      <div className={styles.footerMiddleContainer}>
        {!props.isIFrame && (
          <div
            style={{ marginRight: 8, display: 'flex', alignItems: 'center' }}
          >
            Copyright © {new Date().getFullYear()} Nect GmbH.
          </div>
        )}
        <div className={styles.footerBlock}>
          <Link
            isMobile={!isWideScreen}
            text={t('Imprint')}
            href={getImprintLink()}
            onClick={() => {
              postEvent({
                uuid,
                event: isWideScreen
                  ? 'WEB.JUMP.ACTION.FOOTER.IMPRINT'
                  : 'WEB.JUMP.ACTION.MOBILE.MENU.IMPRINT',
                details: {}
              });
            }}
          />

          <Ellipse />

          <Link
            isMobile={!isWideScreen}
            text={t('Data Privacy')}
            href={getPrivacyLink()}
            onClick={() => {
              postEvent({
                uuid,
                event: isWideScreen
                  ? 'WEB.JUMP.ACTION.FOOTER.DATA_PRIVACY'
                  : 'WEB.JUMP.ACTION.MOBILE.MENU.DATA_PRIVACY',
                details: {}
              });
            }}
          />

          <Ellipse />

          <Link
            isMobile={!isWideScreen}
            text={t('Legal Information')}
            href={getLegalLink()}
            onClick={() => {
              postEvent({
                uuid,
                event: isWideScreen
                  ? 'WEB.JUMP.ACTION.FOOTER.LEGAL_INFO'
                  : 'WEB.JUMP.ACTION.MOBILE.MENU.LEGAL_INFO',
                details: {}
              });
            }}
          />
        </div>
      </div>
      <div className={styles.footerBlock}>
        <Link
          isMobile={!isWideScreen}
          text={t('Contact')}
          href={links.contact + '?uuid=' + uuid}
          onClick={() => {
            postEvent({
              uuid,
              event: 'WEB.JUMP.ACTION.MOBILE.MENU.CONTACT',
              details: {}
            });
          }}
        />

        <Ellipse />

        <Link
          isMobile={!isWideScreen}
          text={t('FAQ')}
          href={links.faq + '?uuid=' + uuid}
          onClick={() => {
            postEvent({
              uuid,
              event: isWideScreen
                ? 'WEB.JUMP.ACTION.FOOTER.FAQ'
                : 'WEB.JUMP.ACTION.MOBILE.MENU.FAQ',
              details: {}
            });
          }}
        />

        {isWideScreen && (
          <>
            <Ellipse />

            <Link
              isMobile={false}
              text={t('Feedback')}
              href={'#'}
              onClick={e => {
                e.preventDefault();
                dispatch({ type: 'CHANGE_FEEDBACK_VISIBILITY', payload: true });

                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.FOOTER.FEEDBACK',
                  details: {}
                });
              }}
            />
          </>
        )}
      </div>
    </footer>
  );
};
