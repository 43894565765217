import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { useWideScreen } from '../../hooks/useWide';
import { SMSStyled } from './SMS.styled';
import { SMSButton } from './SMSButton';
import { initialSmsState, smsReducer, SMS_TEXTS } from './smsReducer';
import { smsSelectOptions } from './smsSelectOptions';
import { smsSelectStyles } from './smsSelectStyles';
import { SMSCheckBox } from './SMSCheckBox';
import { postEvent } from 'src/api/postEvent';
import { useStateValue } from 'src/hooks/useState';

export const SMS = () => {
  const isWideScreen = useWideScreen();
  const { t } = useTranslation();
  const [{ uuid }] = useStateValue();

  const [state, dispatch] = useReducer(smsReducer, initialSmsState);

  if (!isWideScreen) {
    return null;
  }

  const onInputChange = (e: any) => {
    const value = e.currentTarget.value;
    let newText = value;

    if (value === '') {
      dispatch({ type: 'CLEAR_INPUT_ERROR' });
    }

    if (/^\d+$|\s/.test(value) || value === '') {
      const formattedValue = parsePhoneNumberFromString(
        `+${getCountryCallingCode(state.option.value)}${value}`
      );

      if (
        formattedValue &&
        (formattedValue.nationalNumber.length < 7 || !formattedValue.isValid())
      ) {
        dispatch({ type: 'SET_DISABLE_BUTTON' });
      } else {
        const newTextFormatted = formattedValue?.format('INTERNATIONAL');

        if (newTextFormatted && formattedValue) {
          newText = newTextFormatted.substr(
            formattedValue.countryCallingCode.length + 2,
            newTextFormatted.length + 1
          );

          dispatch({ type: 'CLEAR_INPUT_ERROR' });
        } else {
          dispatch({ type: 'SET_DISABLE_BUTTON' });
        }
      }

      dispatch({ type: 'SET_PHONE_NUMBER', payload: newText });
    }
  };

  return (
    <SMSStyled.Wrapper>
      <SMSStyled.Title>{t(SMS_TEXTS.placeHolderDefault)}</SMSStyled.Title>
      <SMSStyled.Subtitle>
        {t(
          'A link will be sent via SMS containing the link to start the case. Please check your messages.'
        )}
      </SMSStyled.Subtitle>

      <SMSStyled.FieldBoxWrapper>
        <Select
          isSearchable={true}
          filterOption={(option: any, input: string) =>
            option.data.searchData.toLowerCase().includes(input.toLowerCase())
          }
          smsSent={state.smsSent}
          styles={smsSelectStyles}
          options={smsSelectOptions}
          value={{
            ...state.option,
            label: (
              <div
                style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
              >
                <img
                  width={20}
                  style={{ paddingRight: 8 }}
                  src={`${
                    process.env.REACT_APP_CMS
                  }/flags/${state.option.value.toLowerCase()}.svg`}
                  alt="Flag"
                />
                <span>+{getCountryCallingCode(state.option.value)}</span>
              </div>
            )
          }}
          onChange={(opt: any) => {
            try {
              postEvent({
                uuid,
                event: 'WEB.JUMP.ACTION.SMS.PHONECOUNTRYCODE',
                details: {
                  country: opt.value
                }
              });
            } catch (error) {
              postEvent({
                uuid,
                event: 'WEB.JUMP.ERROR',
                details: {
                  error
                }
              });
            }

            dispatch({ type: 'SELECT_OPTION', payload: opt });
          }}
        />

        <SMSStyled.FieldBox>
          <SMSStyled.Input
            className={
              state.inputError ? 'error' : state.smsSent ? 'success' : ''
            }
            onFocus={() => {
              postEvent({
                uuid,
                event: 'WEB.JUMP.ACTION.SMS.PHONEINPUTFOCUS',
                details: {}
              });
            }}
            onBlur={() => {
              if (!state.phoneNumber) {
                dispatch({
                  type: 'SET_PHONE_PLACEHOLDER',
                  payload: SMS_TEXTS.placeHolderDefault
                });
              } else {
                const value = parsePhoneNumberFromString(
                  `+${getCountryCallingCode(state.option.value)}${
                    state.phoneNumber
                  }`
                );

                if (
                  value &&
                  (value.nationalNumber.length < 7 || !value.isValid())
                ) {
                  dispatch({ type: 'SET_INPUT_ERROR' });
                } else {
                  dispatch({ type: 'CLEAR_INPUT_ERROR' });
                }

                if (!state.smsConsentLocked && !state.smsConsentGiven) {
                  dispatch({ type: 'SET_CONSENT_MISSING_ERROR' });
                }
              }
            }}
            placeholder={t(state.inputPlaceHolder)}
            value={state.phoneNumber}
            onInput={onInputChange}
            maxLength={14}
            type="tel"
          />
          {state.inputError && (
            <img
              style={{ height: 16, width: 16, marginLeft: '8px' }}
              src={require('../../assets/newJumpPage/validation-error.svg')}
              alt="Error"
            />
          )}
          {state.smsSent && (
            <img
              style={{ height: 16, width: 16, marginLeft: '8px' }}
              src={require('../../assets/newJumpPage/validation-success.svg')}
              alt="Success"
            />
          )}
        </SMSStyled.FieldBox>
      </SMSStyled.FieldBoxWrapper>

      {state.inputError && (
        <SMSStyled.Error>
          {state.phoneNumber.length
            ? t('Please enter a valid phone number')
            : t('Phone number is required')}
        </SMSStyled.Error>
      )}

      {!state.smsSent && (
        <SMSStyled.Consent>
          <SMSCheckBox state={state} dispatch={dispatch} />
        </SMSStyled.Consent>
      )}

      <SMSButton state={state} dispatch={dispatch} />
    </SMSStyled.Wrapper>
  );
};
