import React from 'react';
import { QRComponent } from '../QRComponent/QRComponent';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStateValue } from 'src/hooks/useState';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  font-size: 18px;
  align-items: flex-start;
`;

const Title = styled.h2`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-size: 18px;

  margin-top: 0;
  margin-bottom: 8px;
  color: #0a0d1b;
`;

const Paragraph = styled.p`
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #4b4f55;

  margin: 0;
`;

export const MiniQR = ({ caseStatus }: { caseStatus: CaseStatus }) => {
  const { t } = useTranslation();

  const [{ caseType }] = useStateValue();

  if (
    (caseStatus && caseStatus.state === 'complete' && !caseStatus.canRetry) ||
    caseType === 1
  ) {
    return null;
  }

  return (
    <Container>
      <QRComponent
        style={{
          width: 95,
          height: 95
        }}
        boxProps={{
          outerBorderRadius: 0,
          outerPadding: 0,
          innerBorderRadius: 0,
          innerPadding: 0
        }}
      />
      <TextContainer>
        <Title>{t('Quit the case too early?')}</Title>
        <Paragraph>
          {t('No problem, just scan the QR code again to resume.')}
        </Paragraph>
      </TextContainer>
    </Container>
  );
};
