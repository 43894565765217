import React, { useCallback } from 'react';

import styles from './Button.module.css';
import { Link } from 'react-router-dom';
import { postEvent } from '../../api/postEvent';
import { useStateValue } from '../../hooks/useState';

interface LinkProps {
  children?: any;
  disabled?: boolean;
  href?: string;
  to?: string;
  onPress?: (e: any) => void;
}

export interface ButtonProps extends LinkProps {
  spinner?: boolean;
  text: any;
  width?: number;
}

const Wrapper = ({ href, to, children, disabled, onPress }: LinkProps) => {
  if (disabled) {
    return children;
  }

  if (href) {
    return (
      <a
        className={styles.a}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  } else if (to) {
    return (
      <a className={styles.a} href={to}>
        {children}
      </a>
    );
  } else if (typeof onPress === 'function') {
    return <a className={styles.a}>{children}</a>;
  } else {
    return children;
  }
};

export const JustTheButton = ({
  disabled,
  text,
  spinner,
  width,
  onPress
}: ButtonProps) => (
  <button
    style={{
      width: width || 'inherit',
      backgroundColor: disabled ? '#A9ABB0' : '#03AFA7',
      cursor: disabled ? 'inherit' : 'pointer'
    }}
    disabled={disabled}
    onClick={onPress}
    className={styles.button}
  >
    {text}
    {spinner && (
      <img
        className={styles.spinner}
        src={require('../../assets/spinner.svg')}
      />
    )}
  </button>
);

export const Button = ({
  spinner = false,
  disabled = false,
  text,
  href,
  width,
  to,
  onPress
}: ButtonProps) => {
  const [{ uuid }] = useStateValue();

  const trackedOnPress = useCallback(
    e => {
      if (typeof onPress === 'function') {
        onPress(e);
      }
    },
    [onPress]
  );

  return (
    <div
      className={styles.container}
      onClick={() =>
        postEvent({
          uuid,
          event: 'WEB.JUMP.BUTTONCLICK',
          details: {
            buttonName: text
          }
        })
      }
    >
      <Wrapper disabled={disabled} href={href} to={to} onPress={trackedOnPress}>
        <JustTheButton
          width={width}
          disabled={disabled}
          text={text}
          spinner={spinner}
        />
      </Wrapper>
    </div>
  );
};
