export const getServerEnvironment = (): ServerEnvironment => {
  if (process.env.REACT_APP_ENV === 'PROD') {
    return 'prod';
  }

  if (process.env.REACT_APP_ENV === 'DEV') {
    return 'dev';
  }

  if (process.env.REACT_APP_ENV === 'INT') {
    return 'int';
  }

  return 'test';
};
