import { getLang } from '../lang';
import { gracefulFetch } from './gracefulFetch';
import { postEvent } from './postEvent';

interface Props {
  uuid: UUID;
  publicSPID?: string | number;
}

let langCode = getLang();

if (
  langCode !== 'de' &&
  langCode !== 'en' &&
  langCode !== 'es' &&
  langCode !== 'pl'
) {
  langCode = 'de';
}

const fallbackSPID = '18138848196775486119';

export const getCoBranding = async ({ uuid, publicSPID }: Props) => {
  let response: Response | null = null;

  if (!publicSPID) {
    return;
  }

  try {
    response = await gracefulFetch({
      fetch: async () =>
        fetch(
          `${process.env.REACT_APP_CMS}/sp/${langCode}/${publicSPID}.json`,
          {
            cache: 'no-store',
            method: 'GET'
          }
        )
    });
  } catch (error) {
    console.warn(error);

    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getCoBranding',
        error,
        publicSPID
      }
    });

    // fallback
    response = await gracefulFetch({
      fetch: async () =>
        fetch(
          `${process.env.REACT_APP_CMS}/sp/${langCode}/${fallbackSPID}.json`,
          {
            cache: 'no-store',
            method: 'GET'
          }
        )
    });
  }

  if (response) {
    return response.json();
  }
};
