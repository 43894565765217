import { removeItem } from './storage';

export const clearLocalStorage = () => {
  removeItem('uuid');
  removeItem('redirect_url');
  removeItem('test_public_spid');
  removeItem('public_spid');
  removeItem('deeplink');
  removeItem('referrer');
};
