export const createClipboardData = ({
  uuid,
  responseURI,
  ref
}: {
  uuid: UUID;
  responseURI?: string;
  ref?: string;
}): string => {
  let clipboardData = '';

  if (uuid) {
    clipboardData = 'uuid=' + uuid;
  }

  if (responseURI) {
    clipboardData = clipboardData + ';responseURI=' + responseURI;
  }

  if (ref) {
    clipboardData = clipboardData + ';ref=' + ref;
  }

  //TODO: [mr] trackID

  return clipboardData;
};
