import styled from 'styled-components';

const Question = styled.p`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: ${(props: any) => props.theme.black};
  margin: 0px;
  @media (max-width: 620px) {
    text-align: left;
  }
  strong {
    color: ${(props: any) => props.theme.blue};
  }
  b {
    color: ${(props: any) => props.theme.red};
  }
  label {
    color: ${(props: any) => props.theme.grey};
  }
`;

const TextAreaField = styled.textarea`
  height: 140px;
  width: 100%;
  flex-grow: 1;
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: ${(props: any) => props.theme.grey700};
  border: 1px solid ${(props: any) => props.theme.grey300};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 16px;
  resize: none;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props: any) => props.theme.grey500};
  }
  :-ms-input-placeholder {
    color: ${(props: any) => props.theme.grey500};
  }
`;

const Marker = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  margin-top: 7px;
  margin-right: 8px;
  background-color: ${(props: any) => props.theme.primary};
`;

const WrapperQuestionTitle = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
`;

export const FormStyled = {
  Question,
  Marker,
  WrapperQuestionTitle,
  TextAreaField
};
