import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { useLocation, useRouter } from '../react-router-hooks';
import { FaqStyled } from '../components/faq/Faq.styled';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  max-width: 740px;
`;

const Image = styled.img`
  max-width: 740px;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.h1`
  margin-bottom: 8px;
  margin-top: 0px;
  text-align: center;

  font-size: 36px;
  line-height: 48px;

  > span {
    color: #03afa7;
  }
`;

const Paragraph = styled.p`
  margin-top: 8px;
  margin-bottom: 40px;
  text-align: center;
  color: #0a0d1b;
`;

export const MaintenanceCoronaScreen = () => {
  const { t } = useTranslation();

  const { location } = useRouter();

  return (
    <div className="root">
      <Header
        isIFrame={location && location.state && location.state.isIFrame}
        noBadge={true}
      />
      <Wrapper id="main" className="main">
        <Container>
          <Image
            src={require('../assets/maintenance-corona-illustration.svg')}
          />
          <Container style={{ maxWidth: 604 }}>
            <Title
              dangerouslySetInnerHTML={{
                __html: t(
                  'Corona affects everyone, including our <span>data lines</span>.'
                )
              }}
            />

            <Paragraph style={{ marginTop: 20 }}>
              {t(
                'A safe distance also applies to our data packs. Unfortunately, it is currently jammed. In order to guide you safely and easily through the identification process, we are now maintaining the necessary distance between the individual processes in our system.'
              )}
            </Paragraph>

            <FaqStyled.Box
              style={{ marginTop: 0, boxSizing: 'border-box', width: '100%' }}
            >
              <img src={require('../assets/clock.svg')} width="24" />

              <FaqStyled.BoxTitle>
                {t('Please be patient - we will handle this together.')}
              </FaqStyled.BoxTitle>

              <FaqStyled.BoxMessage
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Please come back again later and <a href="javascript:window.location.reload();">reload</a> the page before you start a new try.'
                  )
                }}
              />
            </FaqStyled.Box>
          </Container>
        </Container>
      </Wrapper>

      <Footer
        isIFrame={location && location.state && location.state.isIFrame}
      />
    </div>
  );
};
