import React from 'react';

import styles from './ActionBox.module.css';
import { useMedia } from 'react-use';

interface Props {
  imageElement?: any;
}

export const ActionBox = ({ imageElement }: Props) => {
  const isWide = useMedia('(min-width: 620px)');

  if (!isWide) {
    return null;
  }

  return <div className={styles.actionBox}>{imageElement}</div>;
};
