import { gracefulFetch } from './gracefulFetch';
import { getOrCreateTrackID } from '../helper/getOrCreateTrackID';
import { setTimeout } from 'timers';
import { getLang } from '../lang';

interface Props {
  uuid: UUID;
  event: EventName;
  details?: {};
}

const userAgent =
  navigator.userAgent +
  ';Jump:' +
  process.env.REACT_APP_VERSION +
  ';lang=' +
  getLang();

export const postEvent = async ({ uuid, event, details = {} }: Props) => {
  let response: Response | null = null;

  if (/localhost/.test(window.location.href)) {
    console.log(`[analytics]: ${event}, ${JSON.stringify(details)}`);
    return;
  }

  const trackID = getOrCreateTrackID();

  try {
    response = await gracefulFetch({
      fetch: async () =>
        fetch('https://analytics.k8s.nect.com/analytics.php', {
          method: 'POST',
          headers: {
            'X-API-KEY': 'bd571da0-fa75-4354-bba6-424700a27ccb',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            event,
            timestamp: Date.now(),
            uuid,
            details: {
              ...details,
              view: window.location.pathname,
              userAgent,
              trackID
            }
          })
        })
    });
  } catch (e) {
    console.warn(e);
  }

  return response;
};
