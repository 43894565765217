import { setItem, getItem } from './storage';

const generateUniqueTrackID = (): string => {
  let trackID = '';

  try {
    const array = new Uint32Array(8);
    const crypto = window.crypto ? window.crypto : (window as any).msCrypto; // for IE 11

    if (crypto) {
      crypto.getRandomValues(array);
    } else {
      return '';
    }

    for (let i = 0; i < array.length; i++) {
      trackID += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
    }
  } catch (e) {
    console.warn(e);
  }

  return trackID;
};

export const getOrCreateTrackID = (): string => {
  let trackID = '';

  try {
    trackID = getItem('trackID') || '';
  } catch (e) {
    console.warn(e);
  }

  if (!trackID) {
    trackID = generateUniqueTrackID();
    setItem('trackID', trackID);
  }

  return trackID;
};
