import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { getLang } from 'src/lang';
import { setItem } from 'src/helper/storage';
import { useWideScreen } from 'src/hooks/useWide';
import {
  StyledChevron,
  StyledDropdown,
  StyledInputContainer,
  StyledList,
  StyledListItem
} from './languageSelector.styled';

interface Props {
  langs: { label: string; code: string }[];
}

export const LanguageSelector = ({ langs }: Props) => {
  const [langBoxVisible, setLangBoxVisible] = useState(false);
  const [currentLang, setLang] = useState(getLang().toUpperCase());

  const handleLanguageClicked = (lang: string): void => {
    setItem('lang', lang.toLowerCase());
    setLang(lang);
    setLangBoxVisible(false);

    window.location.reload();
  };

  const isWideScreen = useWideScreen();

  return (
    <StyledDropdown visible={langBoxVisible}>
      {isWideScreen ? (
        <>
          <StyledInputContainer
            onClick={(): void => setLangBoxVisible(!langBoxVisible)}
          >
            <span>{currentLang}</span>
            <StyledChevron
              visible={langBoxVisible}
              src={require('../../assets/chevron.svg')}
              alt=""
              width={18}
              height={18}
            />
          </StyledInputContainer>

          <StyledList>
            {langs.map(option => (
              <StyledListItem
                key={option.label}
                onClick={(): void => handleLanguageClicked(option.code)}
              >
                <span>{option.label}</span>
              </StyledListItem>
            ))}
          </StyledList>
        </>
      ) : (
        <>
          <StyledList>
            {langs.map(option => (
              <StyledListItem
                key={option.label}
                onClick={(): void => handleLanguageClicked(option.code)}
              >
                <span>{option.label}</span>
              </StyledListItem>
            ))}
          </StyledList>
          <StyledInputContainer
            onClick={(): void => setLangBoxVisible(!langBoxVisible)}
          >
            <img
              src={require('../../assets/globe.svg')}
              alt=""
              width={16}
              height={16}
            />
            <span>{langs.find(lang => lang.code === currentLang)?.label}</span>
            <StyledChevron
              visible={langBoxVisible}
              src={require('../../assets/chevron.svg')}
              alt=""
              width={18}
              height={18}
            />
          </StyledInputContainer>
        </>
      )}
    </StyledDropdown>
  );
};
