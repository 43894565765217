import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 20px;
  font-size: 14px;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`;

const FieldBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const FieldBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-color: ${(props: any) => props.theme.black};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  height: 40px;
`;

const BottomSpan = styled.span`
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  padding-top: 8px;
  color: ${(props: any) => props.theme.darkGrey};
`;

const BottomSpanError = styled(BottomSpan)`
  color: ${(props: any) => props.theme.red};
`;

export const SMSStyled = {
  Wrapper,
  FieldBox,
  BottomSpanError,
  BottomSpan,
  FieldBoxWrapper
};

export const StretchColumn = styled.div`
  flex-grow: 1;
`;

export const Button = styled.button`
  padding: 0;
  background-color: ${(props: any) => props.theme.primary};
  width: 28vw;
  max-width: 320px;
  @media (max-width: 768px) {
    width: 100%;
  }
  border-radius: 4px;
  height: 40px;
  color: ${(props: any) => props.theme.white};
  font-weight: bold;
  border: none;
  cursor: pointer;
`;
