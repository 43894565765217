import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const TuevBadge = ({ isLoaded = true }: { isLoaded?: boolean }) => {
  return (
    <div
      style={{
        flexDirection: 'column',
        paddingTop: 25
      }}
    >
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'flex-start'
        }}
      >
        {isLoaded ? (
          <>
            <a
              href={'https://www.tuev-saar.de/zertifikat/tk44727/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                style={{ width: 104, height: 54, paddingRight: 16 }}
                src={require('../../assets/tuv-tk44727.jpg')}
              />
            </a>
          </>
        ) : (
          <>
            <Skeleton width={72} height={72} style={{ marginRight: 40 }} />
            <Skeleton width={72} height={72} />
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        {isLoaded ? (
          <img
            style={{ width: 322, paddingTop: 16 }}
            src={
              /de/i.test(navigator.language)
                ? require('../../assets/rate-info-de.png')
                : require('../../assets/rate-info-en.png')
            }
          />
        ) : (
          <Skeleton width={380} height={16} style={{ marginTop: 40 }} />
        )}
      </div>
    </div>
  );
};
