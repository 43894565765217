import React from 'react';

import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { Button } from '../components/Button/Button';
import { routes } from '../constants/constants';
import { useLocationPrepare } from '../hooks/useLocationPrepare';

export const OfflineScreen = () => {
  useLocationPrepare();

  return (
    <div className="root">
      <Header />

      <div id="main" className="main">
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img width={'100%'} src={require('../assets/offline.svg')} />

            <h2 style={{ textAlign: 'center' }}>
              Oops! Seems like you have reached a dead-end.
            </h2>

            <Button text={'Back to homepage'} to={routes.HOME} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
