export const prepareRedirectURL = ({
  url,
  uuid
}: {
  url: string;
  uuid: string;
}): string => {
  if (!url || !url.length) {
    return url;
  }

  // double decode, to cover cases like 'com%252F%253Fu' -> 'com://u' (see tests)
  const decodedURL = decodeURIComponent(decodeURIComponent(url)) || '';

  return decodedURL.replace('{{uuid}}', uuid);
};
