import { getLang } from 'src/lang';

export const routes = {
  HOME: '/',
  HOME_FOOTER: '/',
  QR: '/qr',
  IDENT: '/ident',
  SMS: '/sms',
  OFFLINE: '/offline',
  INCOMPATIBILITY: '/incompatibility',
  IFRAME: '/iframe',
  NOCASE: '/nocase',
  STARTIDENT: '/start-ident'
};

export const initialState = {
  uuid: '',
  caseStatus: {
    state: '',
    publicSPID: undefined,
    success: false
  },
  caseInfo: {},
  coBranding: null,
  maintenance: false,
  feedbackBoxVisible: false
};

export const links = {
  contact: 'https://nect.com/support/helpform',
  feedback: 'https://nect.com/support/feedbackform',
  faq: 'https://nect.com/support/faq/main/',
  twitter: 'https://twitter.com/NiceToNectYou',
  facebook: 'https://www.facebook.com/NiceToNectYou/',
  medium: 'https://nect.blog/',
  instagram: 'https://www.instagram.com/nect',
  email: 'mailto:hello@nect.com'
};

export const getPrivacyLink = () =>
  `https://nect.com/${getLang()}/data-protection/`;

export const getImprintLink = () => `https://nect.com/${getLang()}/imprint/`;
export const getLegalLink = () => `https://nect.com/${getLang()}/legal-notice/`;
export const getAboutLink = () => `https://nect.com/${getLang()}/team/`;
