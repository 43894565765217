import React from 'react';

import styles from './StateBox.module.css';
import { useStateValue } from '../../hooks/useState';
import { useWideScreen } from '../../hooks/useWide';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { isIdCardCase } from '../../helper/isIdCardCase';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const getCurrentStep = (caseStatus: CaseStatus): number | null => {
  if (!caseStatus) {
    return null;
  }

  const firstStatus = caseStatus.toSubmit[0];

  // media submit finished?
  if (!firstStatus) {
    return null;
  }

  const currentStepIndex = caseStatus.toSubmitInitial.findIndex(
    s =>
      s.docType === firstStatus.docType &&
      s.mediaType === firstStatus.mediaType &&
      s.side === firstStatus.side
  );

  let currentStepNumber = 1;

  const docTypeIdCard = isIdCardCase(caseStatus);

  if (docTypeIdCard) {
    if (currentStepIndex <= 1) {
      currentStepNumber = 1;
    } else if (currentStepIndex == 2) {
      currentStepNumber = 2;
    } else if (currentStepIndex <= 4) {
      currentStepNumber = 3;
    } else {
      currentStepNumber = currentStepIndex - 1;
    }
  } else {
    if (currentStepIndex <= 1) {
      currentStepNumber = 1;
    } else if (currentStepIndex <= 3) {
      currentStepNumber = 2;
    } else {
      currentStepNumber = currentStepIndex - 1;
    }
  }

  return currentStepNumber;
};

const InnerContainer = styled.div`
  width: 300px;
  padding: 32px;
  background: #fafbfc;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h1`
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;

const Paragraph = styled.p`
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
`;

export const StateBox = ({ caseStatus }: { caseStatus: CaseStatus }) => {
  const isWideScreen = useWideScreen();
  const [{ hasChallengeResponse }] = useStateValue();
  const { t } = useTranslation();

  if (
    !isWideScreen ||
    !caseStatus ||
    caseStatus.reason !== '' ||
    (caseStatus.state === 'complete' && !caseStatus.canRetry) ||
    (caseStatus.state === 'pending' && caseStatus.toSubmit.length === 0)
  ) {
    return null;
  }

  const currentStep: Step = getCurrentStep(caseStatus);

  const labelSteps = ['Good start', 'Keep going', 'Almost there'];

  const title = t(
    labelSteps[
      (currentStep || 0) >= labelSteps.length
        ? labelSteps.length - 1
        : currentStep || 0
    ]
  );

  const isReident =
    caseStatus.toSubmitInitial.length === 2 &&
    caseStatus.toSubmitInitial.filter(item => item.docType === 1).length === 2;

  const docTypeIdCard = isIdCardCase(caseStatus);

  const maxStep = isReident
    ? 1
    : caseStatus.toSubmitInitial.filter(item => item.isSupplemental).length +
      (docTypeIdCard ? 3 : 2) +
      (hasChallengeResponse ? 1 : 0);

  return (
    <div className={styles.stateBox}>
      <InnerContainer>
        <img
          style={{ height: 24 }}
          src={require('../../assets/newJumpPage/progress-page-icon.svg')}
        />
        <Title>{title}</Title>
        <ProgressBar
          value={
            caseStatus.state === 'complete' && !caseStatus.canRetry
              ? 100
              : ((currentStep || 0) * 90) / maxStep
          }
        />
        {/* <Paragraph>
          {t('Capturing the front side of your document.')}
        </Paragraph> */}
      </InnerContainer>
    </div>
  );
};
