import React from 'react';
import { useStateValue } from '../hooks/useState';
import { useTranslation } from 'react-i18next';
import { routes } from '../constants/constants';
import { ReviewCollection } from '../components/ReviewCollection/ReviewCollection';
import { DescriptionButton } from '../components/DescriptionButton/DescriptionButton';
import { useRouter } from '../react-router-hooks';

interface Props {
  toggleFooterPageVisible: () => void;
}

export const HomeBottomScreen = ({ toggleFooterPageVisible }: Props) => {
  const { t } = useTranslation();
  const { location } = useRouter();

  const [{ uuid }]: any = useStateValue();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ReviewCollection />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            flexGrow: 1
          }}
        >
          <DescriptionButton
            buttonProps={{
              text:
                !location.search && !uuid
                  ? t('Please go back to our Partner Company')
                  : t('Get Started'),
              disabled: !uuid,
              to: routes.QR
            }}
            bottomText={
              <img
                style={{
                  padding: 15,
                  cursor: 'pointer',
                  transform: 'rotate(180deg)'
                }}
                src={require('../assets/chevron-bottom.svg')}
                onClick={toggleFooterPageVisible}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
