import React from 'react';
import { useWideScreen } from '../../hooks/useWide';

export const Ellipse = () => {
  const isWideScreen = useWideScreen();

  if (isWideScreen) {
    return (
      <div
        style={{
          margin: '0 8px',
          backgroundColor: '#000000',
          width: 2,
          height: 2,
          borderRadius: '100%'
        }}
      />
    );
  }

  return null;
};
