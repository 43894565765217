import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postEvent } from '../api/postEvent';
import { Alert } from '../components/Alert/Alert';
import { BackBox } from '../components/BackBox/BackBox';
import { Header } from '../components/Header/Header';
import { StoreBadge } from '../components/StoreBadge/StoreBadge';
import {
  getImprintLink,
  getLegalLink,
  getPrivacyLink,
  links,
  routes
} from '../constants/constants';
import { copyToClipboard } from '../helper/copyToClipboard';
import { getDeviceType } from '../helper/device';
import { isDeviceIncompatible } from '../helper/isDeviceIncompatible';
import { useDeepStartLink } from '../hooks/useDeepStartLink';
import { changeShowStartButton, useStateValue } from '../hooks/useState';
import { useLocation } from '../react-router-hooks';
import styles from '../components/Header/Header.module.css';
import { StoreBadgeMobileNew } from 'src/components/StoreBadge/StoreBadgeMobileNew';
import { useGetInstalledRelatedApps } from 'src/hooks/useGetInstalledRelatedApps';
import { LanguageSelector } from 'src/components/languageSelector/languageSelector';
import { supportedLanguages } from '../helper/supportedLangs';
import { extractUrlParam } from 'src/helper/extractUrlParam';

let hidden: any;
let visibilityChange: any;

if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof (document as any).msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof (document as any).webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export const HomeScreenMobileNew = () => {
  const { t } = useTranslation();
  const [
    { responseURI, uuid, caseType, caseStatus, coBranding, eIDMode },
    dispatch
  ] = useStateValue();
  const { navigate, location } = useLocation();
  const [type] = useState(getDeviceType());
  const { clipboardData } = useDeepStartLink({
    uuid,
    type,
    responseURI,
    isMobile: true
  });
  const deviceIncompatibility = isDeviceIncompatible({
    userAgent: navigator.userAgent
  });

  if (deviceIncompatibility) {
    // navigate(routes.INCOMPATIBILITY);

    postEvent({
      uuid,
      event: 'WEB.JUMP.DEVICEINCOMPATIBILITY'
    });
  }

  const copyMagic = useCallback(
    e => {
      if ((document as any)[hidden]) {
        copyToClipboard(clipboardData, uuid);

        postEvent({
          uuid,
          event: 'WEB.JUMP.COPYCLIPBOARD',
          details: {
            clipboardData
          }
        });
      }
    },
    [clipboardData]
  );

  useEffect(() => {
    document.addEventListener(visibilityChange, copyMagic, false);

    return () =>
      document.removeEventListener(visibilityChange, copyMagic, false);
  }, [copyMagic]);

  const [src, setSrc] = useState(
    `${process.env.REACT_APP_CMS}/assets/default-jump.png`
  );

  useEffect(() => {
    if (caseStatus && caseStatus.publicSPID && coBranding && coBranding.logo) {
      const url = coBranding.logo;

      fetch(url)
        .then(() => {
          setSrc(url);
        })
        .catch(error => console.warn(error));
    } else {
      setSrc('');
    }
  }, [caseStatus, coBranding]);

  const installedRelatedApps = useGetInstalledRelatedApps();

  const [{ showStartButton }] = useStateValue();

  useEffect(() => {
    const referral = extractUrlParam({
      url: location.search,
      type: 'referral'
    });

    if (referral === 'doublejump') {
      dispatch(changeShowStartButton({ showStartButton: true }));
    }
  }, [location]);

  const shouldShowStartButton =
    (showStartButton && getDeviceType() === 'ios') ||
    installedRelatedApps?.length;

  return (
    <div style={{ height: '100%' }}>
      <div id="main" className="main">
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            {/* {eIDMode && eIDMode !== '' && (
              <div
                className="newPageMessageBanner newPageMessageBanner-blue"
                style={{
                  position: 'initial',
                  transform: 'none',
                  marginBottom: 16
                }}
                dangerouslySetInnerHTML={{
                  __html: t(
                    '<b>Today, the eID service will be updated.</b><br /> <br />This means that the online ID function of your ID card (eID) only works to a limited extent. We apologize for any inconvenience.'
                  )
                }}
              />
            )} */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className={styles.iconMobile}
                style={{ height: 43, width: 37, paddingTop: 0 }}
                src={require('../assets/nect-logo-black.svg')}
              />

              {src && (
                <>
                  <img
                    src={require('../assets/close-cross.png')}
                    style={{ height: 30, marginLeft: 8, marginRight: 8 }}
                  />
                  <img src={src} style={{ height: 40 }} />
                </>
              )}
            </div>
            {/* @TODO Check this later */}
            {/* {caseType === 2 ? (
              <>
                <br />
                <Alert
                  type="warning"
                  fontWeight="normal"
                  message={t(
                    'Due to security regulation, you need to successfully complete an identification process to receive your ticket.'
                  )}
                />
                <br />
              </>
            ) : (
              <>
                <div
                  style={{
                    width: '100vw',
                    borderBottom: '1px solid #DDDEE0',
                    paddingBottom: 10
                  }}
                >
                  <BackBox />
                </div>
                <div
                  style={{
                    width: '90%',
                    padding: 10,
                    margin: '10px 0',
                    border: '2px solid #F5D424',
                    backgroundColor: '#FFFBE7',
                    borderRadius: 8
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      style={{ paddingRight: 10 }}
                      src={require('../assets/warning-icon.svg')}
                    />
                    <p style={{ margin: '5px 0' }}>
                      {t(
                        'To finish the registration, you need to verify yourself with Nect'
                      )}
                    </p>
                  </div>
                </div>
              </>
            )} */}

            <h1
              style={{
                margin: 5,
                fontSize: '32px',
                lineHeight: '40px',
                fontWeight: 300,
                textAlign: 'center',
                maxWidth: 327,
                letterSpacing: '-0.03em',
                marginTop: 24
              }}
            >
              {t('Fast and simple online identification')}
            </h1>

            <p
              style={{
                color: '#4B4F55',
                fontSize: '18px',
                lineHeight: '26px',
                fontWeight: 400,
                maxWidth: 400,
                minHeight: 52,
                margin: '24px 0 16px 0'
              }}
              dangerouslySetInnerHTML={{
                __html: shouldShowStartButton
                  ? t(
                      '<b style="color: #0A0D1B">You are all set!</b><br/>Just open the Nect Wallet to complete the identification automatic process:'
                    )
                  : coBranding?.name
                  ? t(
                      'To continue identifying for <b>{companyName}</b> you need to download the Nect Wallet:'
                    ).replace('{companyName}', coBranding?.name)
                  : t(
                      'To finish the registration, you need to verify yourself with Nect'
                    )
              }}
            />

            <div
              style={{
                width: '100%',
                paddingBottom: 25,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <StoreBadgeMobileNew
                ownButton
                target="_self"
                type={type}
                onBackground="white"
              />
            </div>
          </div>
        </div>
      </div>
      <footer
        style={{
          background: '#FAFBFC',
          padding: '40px 24px',
          marginTop: 100
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 15,
            lineHeight: '22px'
          }}
        >
          <div
            style={{ width: '50%', flexDirection: 'column', display: 'flex' }}
          >
            <span
              style={{
                color: '#0A0D1B',
                fontFamily: 'Poppins',
                fontWeight: 600,
                marginBottom: 16
              }}
            >
              {t('Help Centre')}
            </span>
            <a
              style={{
                marginBottom: 12,
                color: '#4B4F55'
              }}
              href={links.contact + '?uuid=' + uuid}
              onClick={() => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.MOBILE.MENU.CONTACT',
                  details: {}
                });
              }}
            >
              {t('Help Form')}
            </a>
            <a
              style={{
                color: '#4B4F55'
              }}
              href={links.faq + '?uuid=' + uuid}
              onClick={() => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.MOBILE.MENU.FAQ',
                  details: {}
                });
              }}
            >
              {t('FAQ')}
            </a>
          </div>
          <div
            style={{ width: '50%', flexDirection: 'column', display: 'flex' }}
          >
            <span
              style={{
                color: '#0A0D1B',
                fontFamily: 'Poppins',
                fontWeight: 600,
                marginBottom: 16
              }}
            >
              {t('Legal')}
            </span>
            <a
              style={{
                color: '#4B4F55',
                marginBottom: 12
              }}
              href={getImprintLink()}
              onClick={() => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.MOBILE.MENU.IMPRINT',
                  details: {}
                });
              }}
            >
              {t('Imprint')}
            </a>
            <a
              style={{
                color: '#4B4F55',
                marginBottom: 12
              }}
              href={getPrivacyLink()}
              onClick={() => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.MOBILE.MENU.DATA_PRIVACY',
                  details: {}
                });
              }}
            >
              {t('Privacy Policy')}
            </a>
            <a
              style={{
                color: '#4B4F55'
              }}
              href={getLegalLink()}
              onClick={() => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.MOBILE.MENU.LEGAL_INFO',
                  details: {}
                });
              }}
            >
              {t('Legal Information')}
            </a>
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LanguageSelector langs={supportedLanguages} />
        </div>
        <div
          style={{
            width: '100%',
            color: '#797C81',
            textAlign: 'center',
            marginTop: 24
          }}
        >
          {`© Nect GmbH ${new Date().getFullYear()}`}
        </div>
      </footer>
    </div>
  );
};
