import React from 'react';

import styles from './ReviewBox.module.css';
import { useWideScreen } from '../../hooks/useWide';
import { postEvent } from '../../api/postEvent';
import { getItem } from '../../helper/storage';

interface Item {
  text: string;
  date: string;
  link?: string;
  type: 'ios' | 'android';
}

interface Props {
  items: Item[];
}

const Item = ({ item }: { item: Item }) => (
  <a
    style={{ color: '#0A0D1B' }}
    onClick={() => {
      postEvent({
        uuid: getItem('uuid') || '',
        event: 'WEB.JUMP.CLICK',
        details: {
          reviewBox: item.date
        }
      });
    }}
    href={item.link}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className={styles.item}>
      <span>{item.text}</span>
      <div
        style={{
          color: '#797C81',
          display: 'flex',
          paddingTop: 20,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
          <img
            alt="Nect app review"
            className={styles.icon}
            src={
              item.type === 'android'
                ? require('../../assets/android.svg')
                : require('../../assets/apple.svg')
            }
          />
          <span>{item.type === 'android' ? 'Android User' : 'iOS User'}</span>
        </div>
        <span>{item.date}</span>
      </div>
    </div>
  </a>
);

export const ReviewBox = ({ items }: Props) => {
  const isWideScreen = useWideScreen();

  return (
    <div style={{ paddingBottom: 15 }}>
      <div
        style={{
          flexDirection: isWideScreen ? 'row' : 'column',
          flexWrap: 'wrap'
        }}
        className={styles.box}
      >
        {items
          .filter(item => item.type === 'android')
          .map(item => (
            <Item key={item.text} item={item} />
          ))}
      </div>
      <div
        style={{
          flexDirection: isWideScreen ? 'row' : 'column',
          flexWrap: 'wrap'
        }}
        className={styles.box}
      >
        {items
          .filter(item => item.type === 'ios')
          .map(item => (
            <Item key={item.text} item={item} />
          ))}
      </div>
    </div>
  );
};
