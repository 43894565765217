import { theme } from '../../theme';

export const smsSelectStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: 350,
    padding: 10,
    marginTop: 15,
    marginLeft: -5,
    borderBottom: state.selectProps.borderBottom,
    color: state.selectProps.menuColor
  }),
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      paddingLeft: 0,
      marginRight: 0,
      color: theme.black
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: '1px solid #c9c9c9',
    backgroundColor: 'inherit',
    color: state.isSelected ? 'inherit' : 'grey',
    padding: 10
  }),
  control: () => {
    return {
      width: 105,
      paddingRight: 0,
      display: 'flex'
    };
  },
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, padding: 0 };
  }
};
