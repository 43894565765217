import { useCallback, useRef, MutableRefObject, useEffect } from 'react';

interface Props {
  onInterval: (newValue: number) => void;
  onTimerEnded: (endValue: number) => void;
  decrement?: number;
  interval?: number;
}

interface Return {
  startTimer: ({ start, end }: { start: number; end: number }) => void;
}

export const useTimer = ({
  onInterval,
  onTimerEnded,
  decrement = 1,
  interval = 1000
}: Props): Return => {
  const timer: MutableRefObject<number> = useRef(0);
  const countRef: MutableRefObject<number> = useRef(-1);

  const timerCallback = useCallback(() => {
    if (countRef.current < 2) {
      onTimerEnded(countRef.current);
      clearInterval(timer.current);
      console.log('end');

      return;
    }

    countRef.current = countRef.current - decrement;

    onInterval(countRef.current);
  }, [decrement, onInterval, onTimerEnded]);

  const startTimer = useCallback(
    ({ start, end }: { start: number; end: number }) => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = 0;
      }

      countRef.current = Math.ceil((end - start) / 1000);
      timer.current = window.setInterval(timerCallback, interval);
      onInterval(countRef.current);
    },
    [interval, onInterval, timerCallback]
  );

  useEffect(() => () => clearInterval(timer.current), []);

  return { startTimer };
};
