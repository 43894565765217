import React from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getPrivacyLink } from '../../constants/constants';
import { theme } from '../../theme';
import { useLoadedFromApp } from 'src/hooks/useLoadedFromApp';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface IStyledCheckbox {
  checked: boolean;
  error?: boolean;
}

const StyledCheckbox = styled.div<IStyledCheckbox>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  background: ${(props: any) => (props.checked ? props.theme.primary : '#fff')};
  border-color: ${(props: any) => {
    if (!props.checked && props.error) {
      return theme.red;
    }

    return props.checked ? 'transparent' : props.theme.black;
  }};
  border-style: solid;
  border-width: 2px;
  border-radius: 2px;
  transition: all 150ms;

  /* ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  } */

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

interface Props extends IStyledCheckbox {
  disabled: boolean;
  text: string;
  errorText?: string;
  onChange: () => void;
}

const ConsentLink = styled.a`
  display: inline;
  text-decoration: underline;
  color: ${(props: any) => props.theme.primary};
  font-weight: bold;
`;

export const ConsentCheckBox = (props: Props) => {
  const { t } = useTranslation();

  const isLoadedFromApp = useLoadedFromApp();

  return (
    <>
      <label style={{ display: 'flex', cursor: 'pointer' }}>
        <CheckboxContainer className={'checkbox'}>
          <HiddenCheckbox
            disabled={props.disabled}
            checked={props.checked}
            onChange={props.onChange}
          />
          <StyledCheckbox checked={props.checked} error={props.error}>
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.57814 5.26386L8.66634 1.41168e-05L9.66634 0.964046L3.57814 7.44179L0.333008 3.98902L1.33301 2.77512L3.57814 5.26386Z"
                fill={props.checked ? 'white' : 'transparent'}
              />
            </svg>
          </StyledCheckbox>
        </CheckboxContainer>
        <span style={{ paddingLeft: 8, flexGrow: 1 }}>
          {props.text}
          &nbsp;
          <ConsentLink
            target={isLoadedFromApp ? undefined : '_blank'}
            rel={isLoadedFromApp ? undefined : 'noopener noreferrer'}
            href={getPrivacyLink()}
          >
            {t('Privacy Policy')}
          </ConsentLink>
        </span>
      </label>

      {!!props.errorText && props.error && !props.checked ? (
        <div style={{ marginTop: 4 }}>
          <span style={{ color: theme.red }}>{props.errorText}</span>
        </div>
      ) : (
        <div style={{ marginTop: 4 }}>
          <span>&nbsp;</span>
        </div>
      )}
    </>
  );
};
