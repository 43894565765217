const data: { [key: string]: string } = {};

export const setItem = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    data[key] = value;
  }
};

export const getItem = (key: string) => {
  try {
    const ret = window.localStorage.getItem(key);
    return ret;
  } catch (error) {
    return data[key] || null;
  }
};

export const removeItem = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    delete data[key];
  }
};
