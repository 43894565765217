import React from 'react';
import { useRouter } from '../react-router-hooks';
import { useTranslation } from 'react-i18next';
import { Header } from '../components/Header/Header';
import { Button } from '../components/Button/Button';
import { BackBox } from '../components/BackBox/BackBox';
import { DescriptionBox } from '../components/DescriptionBox/DescriptionBox';
import { ActionBox } from '../components/ActionBox/ActionBox';
import { routes } from '../constants/constants';
import { useStateValue } from '../hooks/useState';

interface Props {
  toggleFooterPageVisible: () => void;
}

export const HomeTopScreen = ({ toggleFooterPageVisible }: Props) => {
  const { location } = useRouter();
  const [{ uuid, responseURI }] = useStateValue();

  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <DescriptionBox
            title={t('Nect is fast, safe and easy to use')}
            textElement={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div>
                  <h2 className={'strikeout'}>{t('Video call')}</h2>
                </div>
                <div>
                  <h2 className={'strikeout'}>{t('Waiting time')}</h2>
                </div>
                <div>
                  <h2 className={'strikeout'}>{t('Privacy invasion')}</h2>
                </div>
                <div>
                  <h2
                    style={{
                      display: 'flex',
                      textAlign: 'start'
                    }}
                  >
                    {t('Just')}&nbsp;<strong>{t('Nect')}</strong>
                  </h2>
                </div>
              </div>
            }
            bottomElement={
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Button
                  disabled={!uuid}
                  to={routes.QR}
                  text={
                    !location.search && !uuid
                      ? t('Please go back to our Partner Company')
                      : t('Get Started')
                  }
                />
              </div>
            }
          />
        </div>

        <ActionBox
          imageElement={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                src={require('../assets/mockup-desktop.png')}
                className="mockup"
              />
            </div>
          }
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end'
        }}
      >
        <img
          src={require('../assets/chevron-bottom.svg')}
          style={{ cursor: 'pointer', padding: '75px 0' }}
          onClick={toggleFooterPageVisible}
        />
      </div>
    </div>
  );
};
