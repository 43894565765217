import styled from 'styled-components';

export const LanguageSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 32px 65px 0;
`;

export const StyledDropdown = styled.div<{ visible: boolean }>`
  @media (min-width: 640px) {
    width: 50px;
  }
  > ul {
    visibility: ${({ visible }): string =>
      visible ? 'visible' : 'hidden'} !important;
  }
`;

export const StyledInputContainer = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledList = styled.ul`
  position: absolute;
  padding: 4px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  margin: -185px auto;
  min-width: 30%;

  @media (min-width: 640px) {
    margin: 10px auto;
    min-width: 115px;
    margin-left: -50px;
  }
`;

export const StyledListItem = styled.li`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  background: #fff;
  padding: 10px 20px;

  :hover {
    background: #f2f2f2;
  }
`;

export const StyledChevron = styled.img<{ visible: boolean }>`
  transition: 0.3s;
  transform: ${({ visible }): string => (visible ? 'rotate(-180deg)' : 'none')};
`;
