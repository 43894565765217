import React from 'react';
import { useTranslation } from 'react-i18next';
import { postEvent } from '../api/postEvent';
import { Footer } from '../components/FooterNew/Footer';
import { links } from '../constants/constants';
import { getItem } from '../helper/storage';
import styled from 'styled-components';
import { useLocation, useRouter } from '../react-router-hooks';
import { LanguageSelector } from '../components/languageSelector/languageSelector';
import { LanguageSelectorWrapper } from '../components/languageSelector/languageSelector.styled';
import { supportedLanguages } from '../helper/supportedLangs';

const Button = styled.button`
  padding: 12px 16px;
  font-family: Heebo, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background-color: #0e1528;
  border-radius: 4px;
  height: 40px;
  color: ${(props: any) => props.theme.white};
  border: none;
  cursor: pointer;

  :hover {
    background-color: #1b2a50;
  }

  :disabled {
    background-color: #dddee0;
    cursor: auto;
  }
`;

export const ErrorScreen = () => {
  const { t } = useTranslation();

  const { navigate } = useLocation();
  const { location } = useRouter();

  return (
    <div className="newPageRoot">
      <LanguageSelectorWrapper>
        <LanguageSelector langs={supportedLanguages} />
      </LanguageSelectorWrapper>
      <div className="newPageWrapper">
        <div className="newPageHeaderLogos">
          <img
            style={{ height: 50 }}
            src={require('../assets/nect-logo-black.svg')}
            alt="Nect"
          />
        </div>

        <h1 className="newPageHeaderTitle">{t('Something went wrong')}</h1>

        <p className="newPageHeaderSubtitle">
          {t(
            'We are sorry that this is happening. Please contact us. Our support team will be on their way.'
          )}
        </p>

        <div style={{ padding: '24px' }}>
          <Button
            onClick={() => {
              const uuid = getItem('uuid') || '';
              navigate(links.contact + '?uuid=' + uuid);
              postEvent({ uuid, event: 'WEB.JUMP.NOCASE.HELP' });
            }}
          >
            {t('Contact support')}
          </Button>
        </div>
      </div>

      <Footer
        isIFrame={location && location.state && location.state.isIFrame}
      />
    </div>
  );
};
