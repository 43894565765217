import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormStyled } from './Form.styled';

interface Props {
  question?: string;
  hint: string;
  name: string;
  value: string;
  onChange?: any;
  color?: string;
  style?: any;
}

export const TextAreaFieldForm = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div {...props}>
      {!!props.question && (
        <FormStyled.WrapperQuestionTitle>
          <FormStyled.Marker></FormStyled.Marker>
          <FormStyled.Question
            dangerouslySetInnerHTML={{
              __html: t(props.question)
            }}
          ></FormStyled.Question>
        </FormStyled.WrapperQuestionTitle>
      )}
      <FormStyled.TextAreaField
        name={props.name}
        placeholder={t(props.hint)}
        style={{ borderColor: props.color }}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
};
