import React, { useState, useCallback, Dispatch, useEffect } from 'react';
import { State, InternalAction } from './smsReducer';
import { Button } from './SMS.styled';
import { theme } from '../../theme';
import { useTimer } from './useTimer';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { useStateValue } from '../../hooks/useState';
import {
  parsePhoneNumberFromString,
  getCountryCallingCode
} from 'libphonenumber-js/min';
import { isValidPhoneNo } from './isValidPhoneNo';
import { postSMS } from '../../api/postSMS';
import { setItem } from '../../helper/storage';
import { postEvent } from 'src/api/postEvent';

interface Props {
  state: State;
  dispatch: Dispatch<InternalAction>;
  text?: string;
}

export const SMSButton = (props: Props) => {
  const { t } = useTranslation();

  const [{ uuid }]: any = useStateValue();

  const [attempts, setAttempts] = useLocalStorage<number>(
    `${uuid}-sms-attempts`,
    0
  );
  const [storageConsentGiven, setStorageConsentGiven] = useLocalStorage<
    boolean
  >(`${uuid}-consent`, false);
  const [smsTime, setSmsTime] = useLocalStorage<number>(`${uuid}-sms-time`, -1);

  const [timeoutDisabled, setTimeoutDisabled] = useState(false);
  const [text, setText] = useState(props.text ? props.text : t('Send SMS'));

  const onTimerEnded = useCallback(
    (endValue: number) => {
      setSmsTime(-1);
      setTimeoutDisabled(false);
      setText(props.text ? props.text : t('Send SMS'));
    },
    [props.text, setSmsTime, t]
  );

  const onTimerInterval = useCallback((newValue: number) => {
    setText(String(newValue));
  }, []);

  const { startTimer } = useTimer({
    onTimerEnded: onTimerEnded,
    onInterval: onTimerInterval
  });

  const submitPhoneNumber = useCallback(() => {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ACTION.SMS.SEND_REQUEST',
      details: {}
    });

    if (!storageConsentGiven && !props.state.smsConsentGiven) {
      console.warn('no sms consent given');
      return;
    }

    const parsedNumber = parsePhoneNumberFromString(
      `+${getCountryCallingCode(props.state.option.value)}${
        props.state.phoneNumber
      }`
    );

    if (!parsedNumber || !parsedNumber.isValid()) {
      props.dispatch({ type: 'SET_INPUT_ERROR' });

      if (!props.state.smsConsentLocked && !props.state.smsConsentGiven) {
        props.dispatch({ type: 'SET_CONSENT_MISSING_ERROR' });
      }

      return;
    }

    props.dispatch({ type: 'CLEAR_INPUT_ERROR' });

    const phoneNo = `${parsedNumber.countryCallingCode}${parsedNumber.nationalNumber}`;

    console.log({ phoneNo });

    if (isValidPhoneNo(phoneNo)) {
      setItem(`${uuid}-consent`, 'true');

      props.dispatch({ type: 'SMS_CONSENT_LOCKED' });

      postSMS({ uuid, phoneNo })
        .then(response => {
          if (response && response.ok) {
            setAttempts(attempts + 1);

            postEvent({
              uuid,
              event: 'WEB.JUMP.ACTION.SMS.SEND_RESPONSE',
              details: {}
            });

            if (attempts > 2) {
              alert(
                t(
                  'The sending of SMS is limited to three attempts. Please use one of the alternatives to start the identification on your smartphone.'
                )
              );
              return;
            }

            setTimeoutDisabled(true);

            const start = Date.now();
            let end = start + 30000;

            if (smsTime === -1) {
              setSmsTime(end);
            } else {
              end = smsTime;
            }

            startTimer({ start, end });
          } else {
            alert(t('Error sending'));
          }
        })
        .catch(() => {
          alert(t('Error sending'));
        });
    }
  }, [attempts, props, setAttempts, setSmsTime, smsTime, startTimer, t, uuid]);

  const disabled =
    timeoutDisabled ||
    props.state.sendButtonDisabled ||
    !props.state.smsConsentGiven;

  const backgroundColor =
    disabled || props.state.inputError ? theme.primaryLight : theme.primary;
  const color =
    disabled || props.state.inputError ? theme.primary : theme.white;

  return (
    <Button
      onClick={
        disabled
          ? () => {
              if (
                !props.state.phoneNumber ||
                props.state.phoneNumber.length < 7
              ) {
                props.dispatch({ type: 'SET_INPUT_ERROR' });
              }

              if (
                !props.state.smsConsentLocked &&
                !props.state.smsConsentGiven
              ) {
                props.dispatch({ type: 'SET_CONSENT_MISSING_ERROR' });
              }
            }
          : submitPhoneNumber
      }
      style={{
        backgroundColor,
        color,
        height: 42
      }}
    >
      {text}
    </Button>
  );
};
