export const isIdCardCase = (caseStatus: CaseStatus): boolean => {
  if (
    caseStatus &&
    caseStatus.toSubmitInitial.find(
      item =>
        item.docType !== undefined &&
        item.docType > 1 &&
        item.docType < 500 &&
        item.side === 'front' &&
        item.mediaType === 'video'
    )
  ) {
    return true;
  } else {
    return false;
  }
};
