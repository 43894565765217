import { generateAuthorizationHeader } from './generateAuthorizationHeader';
import { gracefulFetch } from './gracefulFetch';
import { postEvent } from './postEvent';
import { routes } from '../constants/constants';

interface Props {
  uuid: UUID;
}

export const getHealth = async ({ uuid }: Props) => {
  let response: Response | null = null;

  if (!uuid) {
    if (window.location.pathname !== routes.HOME) {
      window.location.pathname = routes.HOME;
    }
    return;
  }

  try {
    const headers = await generateAuthorizationHeader();

    response = await gracefulFetch({
      fetch: async () =>
        fetch(`${process.env.REACT_APP_API}/health`, {
          cache: 'no-store',
          method: 'GET',
          headers
        })
    });
  } catch (error) {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getHealth',
        error: error.message || error,
        stack: error.stack
      }
    });
  }

  if (response && response.ok) {
    const json = await response.json();
    return json;
  } else {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getHealth',
        status: response && response.status
      }
    });
  }
};
