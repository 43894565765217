import { isIpadOS } from './device';

export const getStoreLink = ({
  env,
  additionalData,
  isMobile,
  type
}: {
  additionalData?: string;
  env: ServerEnvironment;
  isMobile: boolean;
  type: OSType;
}): string => {
  // we don't know if the user is on android or ios, so default to ios
  if (type === null) {
    return 'https://itunes.apple.com/us/app/nect/id1331065474';
  }

  if (type === 'ios') {
    const ipadOS = isIpadOS();

    // prevent showing this link on ipadOS, since it might not work
    if (isMobile && !ipadOS) {
      return 'itms-apps://itunes.apple.com/us/app/nect/id1331065474';
    } else if (isMobile) {
      return 'https://itunes.apple.com/us/app/nect/id1331065474';
    } else if (!isMobile && env === 'prod') {
      return 'https://itunes.apple.com/us/app/nect/id1331065474';
    } else if (!isMobile) {
      return 'https://itunes.apple.com/us/app/nect/id1331065474';
    } else {
      return 'https://itunes.apple.com/us/app/nect/id1331065474';
    }
  } else {
    if (isMobile && env === 'prod') {
      return 'market://details?id=com.nect.app.prod&referrer=' + additionalData;
    } else if (!isMobile && env === 'prod') {
      return 'https://play.google.com/store/apps/details?id=com.nect.app.prod';
    } else if (!isMobile) {
      return 'https://play.google.com/store/apps/details?id=com.nect.app.test';
    } else {
      return 'market://details?id=com.nect.app.test&referrer=' + additionalData;
    }
  }
};
