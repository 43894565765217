import React from 'react';

import styles from './FooterSocial.module.css';
import { links } from '../../../constants/constants';
import { useWideScreen } from '../../../hooks/useWide';

interface Icon {
  resource: string;
  alt: string;
  href: string;
}

const Icon = ({ resource, alt, href }: Icon) => (
  <a href={href}>
    <img className={styles.img} src={resource} alt={alt} />
  </a>
);

export const FooterSocial = () => {
  const isWideScreen = useWideScreen();

  return (
    <div className={styles.row}>
      {isWideScreen && (
        <h2 style={{ margin: 0, paddingRight: 20 }}>Nice to Nect You!</h2>
      )}

      <Icon
        href={links.twitter}
        resource={require('../../../assets/twitter.svg')}
        alt=""
      />
      <Icon
        href={links.facebook}
        resource={require('../../../assets/facebook.svg')}
        alt=""
      />
      <Icon
        href={links.medium}
        resource={require('../../../assets/medium.svg')}
        alt=""
      />
      <Icon
        href={links.instagram}
        resource={
          isWideScreen
            ? require('../../../assets/instagram.svg')
            : require('../../../assets/instagram-mobile.svg')
        }
        alt=""
      />
      <Icon
        href={links.email}
        resource={
          isWideScreen
            ? require('../../../assets/email.svg')
            : require('../../../assets/email-mobile.svg')
        }
        alt=""
      />
    </div>
  );
};
