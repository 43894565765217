export const extractUrlParam = ({
  url,
  type
}: {
  url: string;
  type:
    | 'redirect_url'
    | 'test_public_spid'
    | 'uuid'
    | 'model'
    | 'appVersion'
    | 'systemVersion'
    | 'customer'
    | 'referral'
    | 'dotApp'
    | 'rating';
}): string => {
  const param = type;

  const indexOfUrlParam = url.indexOf(param + '=');

  if (indexOfUrlParam > -1) {
    const seperator = url.indexOf('&', indexOfUrlParam);
    if (seperator > -1) {
      return url.substring(indexOfUrlParam + (param + '=').length, seperator);
    } else {
      return url.substring(indexOfUrlParam + (param + '=').length);
    }
  } else {
    return '';
  }
};
