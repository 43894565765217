import { gracefulFetch } from './gracefulFetch';
import { postEvent } from './postEvent';
import { getItem, setItem } from '../helper/storage';

export const generateAuthorizationHeader = async () => {
  let authorization = getItem('nect-authorization');

  if (!authorization) {
    let response: Response | null = null;

    try {
      response = await gracefulFetch({
        fetch: () =>
          fetch(process.env.REACT_APP_API + '/devices?authType=Basic', {
            method: 'POST'
          })
      });
    } catch (e) {
      console.warn(e);
    }

    if (response && response.ok) {
      console.log('new device registered!');
      const json = await response.json();

      authorization =
        json.authType + ' ' + btoa(json.deviceID + ':' + json.secret); // Basic <devID>:<deviceSecret>
      setItem('nect-authorization', authorization);

      postEvent({
        uuid: getItem('uuid') || '',
        event: 'WEB.JUMP.REGISTER',
        details: {
          deviceID: json.deviceID
        }
      });
    } else {
      throw new Error('authorization unsuccessful');
    }
  }

  return {
    Authorization: authorization,
    'Cache-Control': 'no-cache',
    pragma: 'no-cache'
  };
};
