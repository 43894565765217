import React from 'react';
import { useLocationPrepare } from '../hooks/useLocationPrepare';
import { HomeBottomScreen } from './HomeBottomScreen';
import { MaintenanceScreen } from './MaintenanceScreen';
import { MaintenanceCoronaScreen } from './MaintenanceCoronaScreen';
import { HomeTopScreen } from './HomeTopScreen';
import { useWideScreen } from '../hooks/useWide';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../hooks/useState';
import { HomeScreenMobileNew } from './HomeScreenMobileNew';
import { MaintenanceLoadScreen } from './MaintenanceLoadScreen';

export const HomeScreen = () => {
  useLocationPrepare();
  const { t } = useTranslation();
  const [{ maintenance }] = useStateValue();

  const isWideScreen = useWideScreen();

  if (maintenance === 'LOAD') {
    return <MaintenanceLoadScreen />;
  } else if (maintenance === 'BANANA') {
    return <MaintenanceScreen />;
  } else if (maintenance === 'CORONA') {
    return <MaintenanceCoronaScreen />;
  }

  if (!isWideScreen) {
    return <HomeScreenMobileNew />;
  }

  return (
    <div className="root">
      <Header showStartButton />
      <div id="main" className="main">
        <div>
          <HomeTopScreen
            toggleFooterPageVisible={() => {
              window.scroll(0, 15000);
            }}
          />
          <HomeBottomScreen
            toggleFooterPageVisible={() => {
              window.scroll(0, 0);
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
