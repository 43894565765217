import { TextButtonMediumCSS } from '@nect/ui/component/text/text.styled';
import React from 'react';

import styled from 'styled-components';

const Button = styled.button`
  background-color: ${(props: any) => props.theme.black};
  font-size: 0.7rem;
  line-height: 0.85rem;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 40px;
  padding-right: 40px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${TextButtonMediumCSS}
  color: ${(props: any) => props.theme.white};

  :disabled {
    color: ${(props: any) => props.theme.grey500};
    background-color: ${(props: any) => props.theme.grey200};
  }
`;

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode[];
  style?: React.CSSProperties;
  type?: string;
  disabled?: boolean;
}

export const DarkButton = (props: Props) => {
  return (
    <Button
      onClick={props.onClick}
      style={props.style}
      type={props.type as any}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};
