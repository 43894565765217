export const isDeviceIncompatible = ({ userAgent }: { userAgent: string }) => {
  let osVersion = 0;

  if (!userAgent) {
    return;
  }

  if (userAgent.indexOf('Android') >= 0) {
    osVersion = parseFloat(userAgent.slice(userAgent.indexOf('Android') + 8));
    if (osVersion < 6) return true;
  } else if (userAgent.indexOf('OS') >= 0) {
    osVersion = parseFloat(
      userAgent.substr(userAgent.indexOf('OS ') + 3, 5).replace(/_/g, '.')
    );
    if (osVersion < 10.3) return true;
  }
  return false;
};
