export const supportedLanguages = [
  {
    label: 'English',
    code: 'EN'
  },
  {
    label: 'Deutsch',
    code: 'DE'
  },
  {
    label: 'Español',
    code: 'ES'
  },
  {
    label: 'Polski',
    code: 'PL'
  }
];
