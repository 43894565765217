import React from 'react';

import styles from './StoreBadge.module.css';
import { useWideScreen } from '../../hooks/useWide';
import { getDeviceType, isFirefox } from '../../helper/device';
import { changeShowStartButton, useStateValue } from '../../hooks/useState';
import { useDeepStartLink } from '../../hooks/useDeepStartLink';
import { useTranslation } from 'react-i18next';
import { postEvent } from '../../api/postEvent';
import { useGetInstalledRelatedApps } from '../../hooks/useGetInstalledRelatedApps';

interface Props {
  style?: any;
  target?: string;
  ownButton?: boolean;
  type: OSType;
  href?: string;
  storeLink?: string;
  onClick?: () => void;
  onBackground?: 'white' | 'black' | 'header';
  isFormHelp?: boolean;
  isFormFeedback?: boolean;
}

export const StoreBadge = ({
  style,
  type,
  onBackground = 'black',
  ownButton,
  isFormHelp,
  isFormFeedback,
  target = '_blank'
}: Props) => {
  const isWideScreen = useWideScreen();
  const installedRelatedApps = useGetInstalledRelatedApps();

  const { t } = useTranslation();
  const [{ responseURI, uuid, showStartButton }, dispatch] = useStateValue();

  const shouldShowStartButton =
    (showStartButton && getDeviceType() === 'ios') ||
    installedRelatedApps?.length;

  if (type === 'depending') {
    type = getDeviceType();
  }

  const {
    directLink,
    storeLink,
    copyMagic,
    onBadgeClick,
    deepLink
  } = useDeepStartLink({
    uuid,
    type,
    responseURI,
    isMobile: !isWideScreen
  });

  let link = storeLink;
  let src = '';

  if (type === 'android') {
    src = require('../../assets/store-button/google-play-badge-' +
      t('langCode') +
      '.png');
    if (!isFirefox() && shouldShowStartButton && !isWideScreen) {
      link = deepLink;
    }
  } else {
    src = require('../../assets/store-button/app-store-badge-' +
      t('langCode') +
      '.png');
    if (shouldShowStartButton && !isWideScreen) {
      link = directLink;
    }
  }
  if (isFormHelp || isFormFeedback) {
    return null;
  }
  if (isWideScreen) {
    return (
      <a
        href={link}
        target={'_blank'}
        rel="noopener noreferrer"
        className={styles.badge}
        onClick={e => {
          copyMagic();

          postEvent({
            uuid,
            event: 'WEB.JUMP.APP',
            details: {
              directLink,
              storeLink
            }
          });

          postEvent({
            uuid,
            event:
              type === 'ios'
                ? 'WEB.JUMP.ACTION.APPSTORE'
                : 'WEB.JUMP.ACTION.PLAYSTORE',
            details: {
              directLink,
              storeLink
            }
          });

          if (!shouldShowStartButton) {
            dispatch(changeShowStartButton({ showStartButton: true }));
            onBadgeClick();
          }
        }}
      >
        {onBackground !== 'header' && (
          <img style={{ height: '100%' }} src={src} />
        )}
        {onBackground === 'header' && t('Download')}
      </a>
    );
  }

  if (ownButton) {
    return (
      <a
        href={link}
        target={'_blank'}
        rel="noopener noreferrer"
        className={styles.button}
        style={{ ...style }}
        onClick={e => {
          if (!uuid) {
            e.preventDefault();
            return;
          }

          copyMagic();

          postEvent({
            uuid,
            event: 'WEB.JUMP.APP',
            details: {
              directLink,
              type,
              isWideScreen,
              storeLink
            }
          });

          postEvent({
            uuid,
            event:
              onBackground !== 'header'
                ? 'WEB.JUMP.ACTION.MOBILE.BODYCTA'
                : 'WEB.JUMP.ACTION.MOBILE.HEADERCTA',
            details: {
              directLink,
              storeLink,
              type,
              isWideScreen,
              pathname: window.location.pathname
            }
          });

          if (!shouldShowStartButton) {
            dispatch(changeShowStartButton({ showStartButton: true }));
            onBadgeClick();
            e.preventDefault();
          }
        }}
      >
        {onBackground !== 'header' && !shouldShowStartButton && (
          <>
            <img
              src={
                type === 'android'
                  ? require('../../assets/triangle-android.svg')
                  : require('../../assets/white-apple.svg')
              }
            />
            &nbsp;&nbsp;
          </>
        )}

        {shouldShowStartButton
          ? onBackground !== 'header'
            ? t('Start Identification')
            : t('Start')
          : onBackground !== 'header'
          ? t('Download Nect App')
          : t('Download App')}
      </a>
    );
  }

  return (
    <a
      href={link}
      target={target}
      className={styles.badge}
      onClick={() => {
        copyMagic();

        postEvent({
          uuid,
          event: 'WEB.JUMP.APP',
          details: {
            directLink,
            storeLink,
            type,
            isWideScreen
          }
        });

        postEvent({
          uuid,
          event:
            onBackground !== 'header'
              ? 'WEB.JUMP.ACTION.MOBILE.BODYCTA'
              : 'WEB.JUMP.ACTION.MOBILE.HEADERCTA',
          details: {
            directLink,
            storeLink,
            type,
            isWideScreen,
            pathname: window.location.pathname
          }
        });

        dispatch(changeShowStartButton({ showStartButton: true }));
      }}
    >
      {onBackground !== 'header' && (
        <img style={{ width: '100%', height: '100%' }} src={src} />
      )}
      {!isFormFeedback &&
        !isFormHelp &&
        onBackground === 'header' &&
        t('Download')}
    </a>
  );
};
