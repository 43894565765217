import React, { useState, useEffect } from 'react';

import { useStateValue } from '../hooks/useState';
import { useTranslation } from 'react-i18next';
import { Footer } from '../components/FooterNew/Footer';
import { useLocationPrepare } from '../hooks/useLocationPrepare';
import { QRComponent } from '../components/QRComponentNew/QRComponent';
import { SMS } from '../components/smsNew/SMS';
import { useRouter } from '../react-router-hooks';
import Skeleton from 'react-loading-skeleton';
import { MaintenanceScreen } from './MaintenanceScreen';
import { MaintenanceCoronaScreen } from './MaintenanceCoronaScreen';
import { useWideScreen } from '../hooks/useWide';
import { LanguageSelector } from '../components/languageSelector/languageSelector';
import { LanguageSelectorWrapper } from '../components/languageSelector/languageSelector.styled';
import { supportedLanguages } from '../helper/supportedLangs';
import { MaintenanceLoadScreen } from './MaintenanceLoadScreen';

export const QRScreen = () => {
  useLocationPrepare();
  const { t } = useTranslation();

  const [
    { uuid, responseURI, caseStatus, coBranding, eIDMode, maintenance }
  ]: any = useStateValue();

  const isWideScreen = useWideScreen();

  const isLoaded = !!uuid;

  const [url, setUrl] = useState('');
  const [spLogo, setSpLogo] = useState('');

  const { location } = useRouter();

  useEffect(() => {
    if (uuid) {
      let endpoint = 'https://nect.com/app/?uuid=' + uuid;

      if (responseURI) {
        endpoint += '&responseURI=' + responseURI;
      }

      setUrl(endpoint);
    }
  }, [uuid, responseURI]);

  useEffect(() => {
    if (coBranding?.logo_b64) {
      setSpLogo(coBranding.logo_b64);
    }
  }, [coBranding]);

  if (maintenance === 'LOAD') {
    return <MaintenanceLoadScreen />;
  } else if (maintenance === 'BANANA') {
    return <MaintenanceScreen />;
  } else if (maintenance === 'CORONA') {
    return <MaintenanceCoronaScreen />;
  }

  return (
    <div className="newPageRoot">
      <LanguageSelectorWrapper>
        <LanguageSelector langs={supportedLanguages} />
      </LanguageSelectorWrapper>
      <div className="newPageWrapper">
        {/* {eIDMode && eIDMode !== '' && (
          <div
            className="newPageMessageBanner newPageMessageBanner-blue"
            style={{ marginBottom: 24 }}
            dangerouslySetInnerHTML={{
              __html: t(
                '<b>Today, the eID service will be updated.</b><br /> <br />This means that the online ID function of your ID card (eID) only works to a limited extent. We apologize for any inconvenience.'
              )
            }}
          />
        )} */}

        <div className="newPageHeaderLogos">
          <img
            style={{ height: 50 }}
            src={require('../assets/nect-logo-black.svg')}
            alt="Nect"
          />
          {isWideScreen && spLogo && (
            <>
              <img
                src={require('../assets/close-cross.png')}
                style={{ height: 40, marginLeft: 16, marginRight: 16 }}
                alt="Close"
              />
              <img src={spLogo} style={{ height: 50 }} alt="Service Provider" />
            </>
          )}
        </div>

        <h1 className="newPageHeaderTitle">
          {t('Fast and simple online identification')}
        </h1>

        {isLoaded ? (
          <p
            className="newPageHeaderSubtitle"
            dangerouslySetInnerHTML={{
              __html:
                coBranding && coBranding.name
                  ? t(
                      'Choose one of the following options to identify for <b>{companyName}</b>:'
                    ).replace('{companyName}', coBranding.name)
                  : t('Choose one of the following options to identify:')
            }}
          />
        ) : (
          <Skeleton width={500} height={64} />
        )}

        <div className="newPageCaseStartWrapper">
          <div className="newPageCaseStartQR">
            <div className="newPageCaseStartQRTitle">
              <img
                style={{ height: 80 }}
                src={require('../assets/newJumpPage/scan.svg')}
                alt="Scan"
              />
              <p>{t('Open your camera app and scan to start.')}</p>
            </div>

            {isLoaded ? <QRComponent /> : <Skeleton width={220} height={220} />}
          </div>
          <div className="newPageCaseStartLine">
            <div className="newPageCaseStartLineVL">
              <div className="newPageCaseStartLabel">{t('OR')}</div>
            </div>
          </div>
          <div className="newPageCaseStartSMS">
            <div className="newPageCaseStartSMSTitle">
              <img
                style={{ height: 80 }}
                src={require('../assets/newJumpPage/sms.svg')}
                alt="SMS"
              />
              <p>
                {t('Send yourself a link to start the identification process.')}
              </p>
            </div>

            {isLoaded ? (
              <SMS />
            ) : (
              <>
                <Skeleton width={280} height={180} />
                <br />
                <Skeleton width={280} height={40} />
              </>
            )}
          </div>
        </div>
      </div>

      <Footer
        isIFrame={location && location.state && location.state.isIFrame}
      />
    </div>
  );
};
