import { useMedia } from 'react-use';
import { isIpadOS, isMobile, isTablet } from '../helper/device';

export const useWideScreen = () => {
  const isWide = useMedia('(min-width: 620px)');

  if (isMobile() || isTablet() || isIpadOS()) {
    return false;
  }

  return isWide;
};
