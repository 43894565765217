import React, { useEffect, useState } from 'react';

import QRCode from 'qrcode.react';
import { useStateValue } from '../../hooks/useState';

import { useWideScreen } from '../../hooks/useWide';
import { getServerEnvironment } from '../../helper/getServerEnvironment';

import { Loader } from '../Loader/Loader';
import { getDirectLink } from 'src/helper/getDirectLink';

export const QRComponent = () => {
  const isWide = useWideScreen();

  const [{ uuid, responseURI }]: any = useStateValue();

  const [url, setUrl] = useState('');

  useEffect(() => {
    if (uuid) {
      const env = getServerEnvironment();

      const endpoint = getDirectLink({ uuid, responseURI, env });

      setUrl(endpoint);
    }
  }, [uuid, responseURI]);

  if (!isWide) {
    return null;
  }

  return (
    <div
      style={{
        background: 'white'
      }}
    >
      {url ? (
        <QRCode
          style={{
            maxWidth: 220,
            maxHeight: 220
          }}
          value={url}
          size={220}
          fgColor={'#2B2B2E'}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};
