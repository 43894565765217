/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/interface-name-prefix */
import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { postEvent } from '../../api/postEvent';
import { useStateValue } from '../../hooks/useState';
import {
  Divider,
  FeedbackItemBox,
  RightRow,
  Row,
  Wrapper,
  ThankYouMessageStyled
} from './feedbackBox.styled';
import styled from 'styled-components';
import { TextAreaFieldForm } from '../form/TextAreaField';
import { DarkButton } from '../DarkButton';

const SubTitle = styled.h2`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #0a0d1b;
  margin: 0;
`;

const choices = [
  { option: 'A', value: "I don't have my document with me" },
  { option: 'B', value: "I don't have time to do the identification now" },
  { option: 'C', value: "I don't want to download the Nect app" },
  {
    option: 'D',
    value: 'I do not understand what to do next',
    subChoices: [
      { option: 'A', value: "I don't know what to do with the QR code" },
      { option: 'B', value: "I don't know where the QR code is" },
      { option: 'C', value: "I don't understand why my number is needed" },
      { option: 'D', value: 'I cannot find the Nect App' },
      { option: 'E', value: 'What is Nect?' },
      { option: 'F', value: 'I need more information regarding the process' }
    ]
  },
  { option: 'E', value: 'I got an error message' },
  { option: 'F', value: 'Other reasons' }
];

interface Props {
  onClose: () => void;
  onSubmitted: (newSubmitted: boolean) => void;
}

export const ItemBox = (props: Props) => {
  const { t } = useTranslation();
  const [{ uuid }]: any = useStateValue();
  const [choice, setChoice] = useState<string | undefined>();
  const [subChoice, setSubChoice] = useState<string>();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isComplete, setIsComplete] = useState(false);

  const onChoiceSelect = useCallback(
    (newChoice: string) => {
      if (choice) {
        if (
          choice === 'I do not understand what to do next' ||
          choice === 'I got an error message'
        ) {
          if (subChoice === undefined) {
            postEvent({
              uuid,
              event: 'WEB.JUMP.FEEDBACK.SELECT',
              details: { newChoice }
            });
            setSubChoice(newChoice);
            setIsComplete(true);
          }
          return;
        }

        return;
      }
      setChoice(newChoice);
      props.onSubmitted(true);

      postEvent({
        uuid,
        event: 'WEB.JUMP.FEEDBACK.SELECT',
        details: { newChoice }
      });

      if (
        newChoice !== 'I do not understand what to do next' &&
        newChoice !== 'I got an error message' &&
        newChoice !== 'Other reasons'
      ) {
        setIsComplete(true);
      }
    },
    [choice, subChoice, props, uuid]
  );

  const formik = useFormik({
    initialValues: {
      freeText: ''
    },
    onSubmit: async values => {
      await postEvent({
        uuid,
        event: 'WEB.JUMP.FEEDBACK.SUBMIT',
        details: { ...values, choice }
      });

      props.onSubmitted(true);

      formik.resetForm();
      setChoice(undefined);
      setIsComplete(true);
    }
  });

  const subTitle = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    return choice === 'I do not understand what to do next' ? (
      <SubTitle>{t('I do not understand what to do next')}</SubTitle>
    ) : choice === 'I got an error message' ? (
      <SubTitle>{t('What error message did you see?')}</SubTitle>
    ) : choice === 'Other reasons' ? (
      <SubTitle>{t('Please describe your experience with Nect.')}</SubTitle>
    ) : (
      <SubTitle>{t('Tell us why and help us improve.')}</SubTitle>
    );
  };

  const handleOnChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    formik.handleChange(e);

    // needs some fine tuning
    if (formik.values.freeText) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  return (
    <>
      {!isComplete && subTitle()}

      <Wrapper>
        {!isComplete && (
          <>
            {choice === 'I do not understand what to do next' ? (
              choices[3].subChoices?.map(item => {
                return (
                  <FeedbackItem
                    key={item.option}
                    onClick={onChoiceSelect}
                    selected={item.value === subChoice}
                    text={item.value}
                    option={item.option}
                  />
                );
              })
            ) : choice === 'I got an error message' ||
              choice === 'Other reasons' ? (
              <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                <TextAreaFieldForm
                  style={{ width: '100%' }}
                  name="freeText"
                  hint="Please type here"
                  value={formik.values.freeText}
                  onChange={handleOnChanged}
                />

                <RightRow>
                  <DarkButton type="submit" disabled={buttonDisabled}>
                    {t('Submit')}
                  </DarkButton>
                </RightRow>
              </form>
            ) : (
              choices.map(item => {
                return (
                  <FeedbackItem
                    key={item.option}
                    onClick={onChoiceSelect}
                    selected={item.value === choice}
                    text={item.value}
                    option={item.option}
                  />
                );
              })
            )}
          </>
        )}

        {isComplete && (
          <ThankYouMessageStyled>
            {t('Thank you for your help!')}
          </ThankYouMessageStyled>
        )}
      </Wrapper>

      <Divider />
    </>
  );
};

export interface IFeedbackItem {
  selected?: boolean;
  text: string;
  option: string;
  onClick: (choice: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FeedbackItem = (props: IFeedbackItem) => {
  const { t } = useTranslation();

  return (
    <Row>
      <FeedbackItemBox {...props} onClick={() => props.onClick(props.text)}>
        <span>{t(props.option)}</span>
        <span>{t(props.text)}</span>
      </FeedbackItemBox>
    </Row>
  );
};
