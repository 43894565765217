import React from 'react';

import styles from './RatingBox.module.css';
import { useStateValue } from '../../hooks/useState';
import { useWideScreen } from '../../hooks/useWide';
import { useDeepStartLink } from '../../hooks/useDeepStartLink';
import { getDeviceType } from '../../helper/device';

interface Props {
  type: 'android' | 'ios';
  number: number;
}

const Icon = ({ type, isMobile }: { type: OSType; isMobile: boolean }) => {
  let src = '';

  if (type === 'android') {
    if (isMobile) {
      src = require('../../assets/google-play-mobile.svg');
    } else {
      src = require('../../assets/google-play.svg');
    }
  }

  if (type === 'ios') {
    if (isMobile) {
      src = require('../../assets/apple-mobile.svg');
    } else {
      src = require('../../assets/apple.svg');
    }
  }

  return <img alt={`${type} app rating`} className={styles.img} src={src} />;
};

export const RatingBox = ({ type, number }: Props) => {
  const isWideScreen = useWideScreen();

  const [{ responseURI, uuid }] = useStateValue();
  const {
    clipboardData,
    directLink,
    storeLink,
    onBadgeClick
  } = useDeepStartLink({
    uuid,
    type,
    responseURI,
    isMobile: !isWideScreen
  });

  return (
    <div className={styles.box}>
      <a
        target={isWideScreen ? '_blank' : '_self'}
        onClick={getDeviceType() !== type ? undefined : onBadgeClick}
        href={
          getDeviceType() !== type
            ? undefined
            : type === 'android' && !isWideScreen
            ? storeLink
            : directLink
        }
      >
        <Icon type={type} isMobile={!isWideScreen} />
      </a>

      <div style={{ display: 'flex' }}>
        <img className={styles.star} src={require('../../assets/star.svg')} />
        <img className={styles.star} src={require('../../assets/star.svg')} />
        <img className={styles.star} src={require('../../assets/star.svg')} />
        <img className={styles.star} src={require('../../assets/star.svg')} />
        <img className={styles.star} src={require('../../assets/star.svg')} />

        <div
          style={{
            color: '#A9ABB0',
            padding: '0 3px',
            display: 'flex',
            alignItems: 'flex-end'
          }}
        >
          (
          <span
            style={{ color: isWideScreen ? '#0A0D1B' : '#fff' }}
            className={styles.bigNumber}
          >
            {number}
          </span>
          /5)
        </div>
      </div>
    </div>
  );
};
