import React from 'react';

import styles from './Footer.module.css';
import { FooterLinks } from './FooterLinks/FooterLinks';
import { useWideScreen } from '../../hooks/useWide';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';

interface Props {
  isIFrame?: boolean;
}

export const Footer = (props: Props) => {
  const { t } = useTranslation();

  const isWideScreen = useWideScreen();

  if (!isWideScreen) {
    return null;
  }

  return (
    <footer className={styles.headerDesktop}>
      <div style={{ display: 'flex' }}>
        <div className={styles.icon}>
          <img
            style={{ height: 30, width: 34 }}
            src={require('../../assets/nect-logo-black.svg')}
          />
        </div>
        <FooterLinks />
      </div>

      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          flexDirection: 'row',
          fontFamily: 'Heebo, sans-serif',
          color: theme.grey,
          lineHeight: '13px',
          fontSize: 10
        }}
      >
        {!props.isIFrame && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ textAlign: 'end' }}>
              Copyright © {new Date().getFullYear()} <strong>Nect </strong>
              GmbH.&nbsp;
              {t('All Rights Reserved.')}
            </span>
          </div>
        )}
      </div>
    </footer>
  );
};
