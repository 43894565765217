import { getServerEnvironment } from '../helper/getServerEnvironment';
import { getDeviceType, isFirefox } from '../helper/device';
import { createClipboardData } from '../helper/createClipboardData';
import { getDirectLink } from '../helper/getDirectLink';
import { getStoreLink } from '../helper/getStoreLink';
import { useCallback } from 'react';
import { copyToClipboard } from '../helper/copyToClipboard';
import { postEvent } from '../api/postEvent';

interface Input {
  isMobile: boolean;
  type: OSType;
  uuid: UUID;
  responseURI?: string;
  ref?: string;
  env?: ServerEnvironment;
}

export const useDeepStartLink = ({
  type,
  uuid,
  responseURI,
  isMobile,
  env,
  ref
}: Input): any => {
  if (type === 'depending') {
    type = getDeviceType();
  }

  if (!env) {
    env = getServerEnvironment();
  }

  const clipboardData = createClipboardData({ uuid, responseURI });
  const directLink = getDirectLink({ uuid, responseURI, env });
  const storeLink = getStoreLink({
    isMobile,
    env,
    type,
    additionalData: clipboardData
  });
  const deepLink = 'nect://ident?' + uuid;

  const copyMagic = useCallback(
    (/* e */) => {
      if (!isMobile) {
        return;
      }

      copyToClipboard(clipboardData, uuid);

      postEvent({
        uuid,
        event: 'WEB.JUMP.COPYCLIPBOARD',
        details: {
          clipboardData
        }
      });
    },
    [clipboardData, isMobile, uuid]
  );

  const onBadgeClick = useCallback(() => {
    postEvent({
      uuid,
      event: 'WEB.JUMP.APP',
      details: {
        directLink,
        storeLink
      }
    });

    if (!isMobile) {
      return;
    }

    copyMagic();

    if (type === 'android') {
      if (!isFirefox()) {
        window.location.href = deepLink;
      }

      setTimeout(() => {
        window.location.href = storeLink;
        window.open(storeLink, '_blank');
      }, 2000);
    } else {
      if (/Version\//i.test(navigator.userAgent)) {
        // special case for Safari on iOS

        if (/OS 10_/i.test(navigator.userAgent)) {
          window.open(storeLink, '_self');
          // window.open(
          //   env === 'test'
          //     ? 'https://beta.itunes.apple.com/v1/app/1380797697'
          //     : 'https://itunes.apple.com/us/app/nect/id1331065474',
          //   '_self'
          // );
          // setTimeout(() => {
          //   window.open(directLink, "_blank");
          // }, 2000);
        } else {
          window.location.assign(storeLink);
          // window.open(
          //   env === 'test'
          //     ? 'https://beta.itunes.apple.com/v1/app/1380797697'
          //     : 'https://itunes.apple.com/us/app/nect/id1331065474',
          //   '_self'
          // );

          // This messes with the start button flow
          // setTimeout(() => {
          //   window.location.assign(directLink);
          // }, 2000);
        }
      } else {
        // special case for non-Safari on iOS
        window.open(
          env === 'test'
            ? 'https://beta.itunes.apple.com/v1/app/1380797697'
            : 'https://itunes.apple.com/us/app/nect/id1331065474',
          '_self'
        );
        window.open(storeLink, '_self');

        setTimeout(() => {
          window.open('nect://ident?' + uuid, '_self');
        }, 2000);
      }
    }
  }, [copyMagic, directLink, env, isMobile, storeLink, type, uuid]);

  return {
    copyMagic,
    onBadgeClick,
    clipboardData,
    directLink,
    storeLink,
    deepLink
  };
};
