export const theme = {
  primary: '#03AFA7',
  primaryLight: '#D4F5ED',
  red: '#D33367',
  black: '#0A0D1B',
  white: '#fff',
  grey: '#797C81',
  grayLight: '#DDDEE0',
  grayButton: '#f0f1f2',
  darkGrey: '#202124',
  bgGrey: '#F5F6F8',
  charcoal: '#4B4F55',
  gray: '#A9ABB0',
  blue: '#2F80ED',
  blueLightShadow: '#ecf3f8',
  blueLight: '#f7fafc',
  blueDark: '#f6fbff',
  snackInfoBase: '#0A6CFF',
  snackInfoLightBase: '#F2F9FF',
  grey700: '#0A0D1B',
  grey200: '#F0F1F2',
  grey300: '#DDDEE0',
  grey400: '#A9ABB0',
  grey500: '#797C81'
};
