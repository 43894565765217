import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { useLocation, useRouter } from '../react-router-hooks';
import { FaqStyled } from '../components/faq/Faq.styled';
import { getHealth } from 'src/api/getHealth';
import { extractUrlParam } from 'src/helper/extractUrlParam';
import { getCaseInfo } from 'src/api/getCaseInfo';
import { postEvent } from 'src/api/postEvent';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  max-width: 740px;
`;

const Image = styled.img`
  max-width: 740px;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.h1`
  margin-bottom: 8px;
  margin-top: 0px;
  text-align: center;

  font-size: 36px;
  line-height: 48px;

  > span {
    color: #03afa7;
  }
`;

const Paragraph = styled.p`
  margin-top: 8px;
  margin-bottom: 40px;
  text-align: center;
  color: #0a0d1b;
`;

export const MaintenanceLoadScreen = () => {
  const { t } = useTranslation();

  const { location } = useRouter();

  useEffect(() => {
    const pageUUID = extractUrlParam({ url: location.search, type: 'uuid' });

    const interval = setInterval(async () => {
      try {
        let healthOutgage = 'LOAD';
        const health = await getHealth({ uuid: pageUUID });
        if (health?.minAPPVersionAndroid) {
          healthOutgage = health.outage;
        }

        let caseInfoOutgage = 'LOAD';
        const caseInfo = await getCaseInfo({ uuid: pageUUID });

        if (caseInfo?.hasChallengeResponse) {
          caseInfoOutgage = caseInfo.outage;
        }

        const outage = healthOutgage || caseInfoOutgage;

        if (!outage) {
          sessionStorage.setItem('queue-place', 'true');
          window.location.reload();
        }
      } catch (error) {
        postEvent({
          uuid: pageUUID,
          event: 'WEB.JUMP.ERROR',
          details: {
            info: 'maintenceLoadScreen',
            error
          }
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="root">
      <Header
        isIFrame={location && location.state && location.state.isIFrame}
        noBadge={true}
      />
      <Wrapper id="main" className="main">
        <Container>
          <Image
            src={require('../assets/maintenance-corona-illustration.svg')}
          />
          <Container style={{ maxWidth: 604 }}>
            <Title
              dangerouslySetInnerHTML={{
                __html: t('Short break in our digital queue')
              }}
            />

            <Paragraph style={{ marginTop: 20 }}>
              {t(
                'We are currently experiencing an unusually high volume of users who want to go through our AI-supported identification process.'
              )}
            </Paragraph>

            <FaqStyled.Box
              style={{
                marginTop: 0,
                boxSizing: 'border-box',
                width: '100%',
                flex: 0
              }}
            >
              <img src={require('../assets/clock.svg')} width="24" />

              <FaqStyled.BoxTitle>{t('Please be patient.')}</FaqStyled.BoxTitle>

              <FaqStyled.BoxMessage
                dangerouslySetInnerHTML={{
                  __html: t(
                    'We are working hard to make the process as fast and smooth as possible for all waiting users. Your place in the queue is secured and you will be automatically redirected as soon as it is your turn.'
                  )
                }}
              />
            </FaqStyled.Box>
          </Container>
        </Container>
      </Wrapper>

      <Footer
        isIFrame={location && location.state && location.state.isIFrame}
      />
    </div>
  );
};
