export const getDirectLink = ({
  uuid,
  responseURI = '',
  referral,
  env
}: {
  uuid?: UUID;
  responseURI?: string;
  referral?: string;
  env: ServerEnvironment;
}): string => {
  let queryString = '?';

  if (uuid) {
    queryString += `uuid=${uuid}&`;
  }
  if (responseURI) {
    queryString += `redirect_url=${responseURI}&`;
  }
  if (referral) {
    queryString += `referral=${referral}`;
  }

  if (env === 'prod') {
    return `https://jump.nect.app/${queryString}`;
  }
  if (env === 'dev') {
    return `https://jump.dev.svc.nect.app/${queryString}`;
  }
  if (env === 'int') {
    return `https://jump.integration.svc.nect.app/${queryString}`;
  }
  return `https://test.jump.nect.app/${queryString}`;
};
