import React, { useEffect, useState, useCallback } from 'react';

import styles from './BackBox.module.css';
import { useStateValue } from '../../hooks/useState';
import { useTranslation } from 'react-i18next';
import { routes } from '../../constants/constants';
import { useWideScreen } from '../../hooks/useWide';
import { useLocation } from '../../react-router-hooks';
import { postEvent } from '../../api/postEvent';
import { Link } from 'react-router-dom';
import { theme } from '../../theme';
import { getItem } from '../../helper/storage';

interface Props {
  text?: string;
  to?: string | { pathname: string; state: { isIFrame: boolean } };
  invisibleBack?: boolean;
}

export const BackBox = ({ text, invisibleBack, to = routes.HOME }: Props) => {
  const isWideScreen = useWideScreen();
  const { t } = useTranslation();
  const [{ caseStatus, coBranding, uuid }]: any = useStateValue();
  const testPublicSPID = getItem('test_public_spid');
  const { location } = useLocation();
  const [src, setSrc] = useState(
    `${process.env.REACT_APP_CMS}/assets/default-jump.png`
  );

  if (!text && coBranding && (coBranding.jump_name || coBranding.name)) {
    text = t('Back');
  }

  if (!text && location.pathname !== routes.HOME) {
    text = t('Back');
  }

  useEffect(() => {
    if (caseStatus && caseStatus.publicSPID && coBranding && coBranding.logo) {
      setSrc(coBranding.logo);
    }

    if (testPublicSPID) {
      setSrc('https://cms.nect.com/assets/' + testPublicSPID + '-jump.png');
    }
  }, [caseStatus, testPublicSPID, text]);

  const sendEvent = useCallback(() => {
    postEvent({
      uuid,
      event: 'WEB.JUMP.CLICK',
      details: {
        text: 'backButton'
      }
    });
  }, [uuid]);

  // no back button on mobile
  if (!isWideScreen) {
    return (
      <div className={styles.backBoxMobile}>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img className={styles.mobileLogo} src={src} />
        </div>
        <div
          style={{
            display: 'flex',
            lineHeight: '1rem',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.grey
          }}
        >
          {t("You're using Nect to verify for ")}
          <br />
          {coBranding && coBranding.name}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.backBox}>
      <div className={styles.row}>
        {!invisibleBack && text && (
          <Link
            to={to}
            style={{
              color: '#797C81',
              fontSize: 13,
              fontFamily: 'Heebo, sans-serif',
              fontWeight: 'normal'
            }}
            onClick={sendEvent}
          >
            <svg
              style={{ paddingRight: 5 }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.27641 8.66672L8.47167 12.862L7.52886 13.8048L1.72412 8.00005L7.52886 2.19531L8.47167 3.13812L4.27641 7.33338H14.0003V8.66672H4.27641Z"
                fill="#797C81"
              />
            </svg>

            {text}
          </Link>
        )}
      </div>
    </div>
  );
};
