import { generateAuthorizationHeader } from './generateAuthorizationHeader';
import { gracefulFetch } from './gracefulFetch';
import { postEvent } from './postEvent';
import { routes } from '../constants/constants';

interface Props {
  uuid: UUID;
}

let c = 10;

const isSelfie = (d: ToSubmit) => d.docType === 1 || !d.side;
const isFront = (d: ToSubmit) => d.side === 'front';
const isNotFront = (d: ToSubmit) => d.side !== 'front';
const isSupp = (d: ToSubmit) => d.isSupplemental;

//TODO: [mr] duplicate code customerapp (but without typescript)
const reorderToSubmit = (toSubmit: ToSubmit[]) => {
  if (!toSubmit || toSubmit.length < 2) {
    return toSubmit;
  }

  return [...toSubmit].sort((a, b) => {
    if (isSupp(a) && !isSupp(b)) {
      return 1;
    } else if (!isSupp(a) && isSupp(b)) {
      return -1;
    }

    if (isFront(a) && isNotFront(b)) {
      return -1;
    }

    if (isNotFront(a) && isFront(b)) {
      return 1;
    }

    if (isSelfie(a) && !isSelfie(b)) {
      return 1;
    } else if (!isSelfie(a) && isSelfie(b)) {
      return -1;
    }

    return 0;
  });
};

export const getCaseStatus = async ({ uuid }: Props) => {
  // return require('../api/mockData/caseStatus.json');
  let response: Response | null = null;

  if (!uuid) {
    if (window.location.pathname !== routes.HOME) {
      window.location.pathname = routes.HOME;
    }
    return;
  }

  try {
    const headers = await generateAuthorizationHeader();

    response = await gracefulFetch({
      fetch: async () =>
        fetch(`${process.env.REACT_APP_API}/cases/${uuid}`, {
          cache: 'no-store',
          method: 'GET',
          headers
        }),
      tries: 1
    });
  } catch (error) {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getCaseStatus',
        error: error.message || error,
        stack: error.stack
      }
    });
  }

  if (response && response.ok) {
    if (c <= 0) {
      postEvent({
        uuid,
        event: 'WEB.JUMP.HEARTBEAT'
      });

      c = 3;
    } else {
      c -= 1;
    }

    const json = await response.json();

    if (json.toSubmit) {
      json.toSubmit = reorderToSubmit(json.toSubmit);
    }

    if (json.toSubmitInitial) {
      json.toSubmitInitial = reorderToSubmit(json.toSubmitInitial);
    }

    return json;
  } else {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getCaseStatus',
        status: response && response.status
      }
    });
  }
};
