import { smsSelectOptions } from './smsSelectOptions';

import { Action } from 'redux';
import { CountryCode } from 'libphonenumber-js';
import { getLang } from 'src/lang';

export interface State {
  sendButtonDisabled: boolean;
  inputPlaceHolder: string;
  inputError: boolean;
  consentError: boolean;
  smsSent: boolean;
  smsConsentLocked: boolean;
  smsConsentGiven: boolean;
  phoneNumber: string;
  buttonText: string;
  option: { value: CountryCode; label: any };
}

export const SMS_TEXTS = {
  placeHolderDefault: 'Your mobile number',
  placeHolderTyped: '000 00000000'
};

const lang = getLang().toUpperCase();
const preselectedCountryCode = lang === 'EN' ? 'DE' : lang;

const defaultOption =
  smsSelectOptions.find((opt: any) => opt.value === preselectedCountryCode) ||
  smsSelectOptions.find((opt: any) => opt.value === 'DE');

export const initialSmsState: State = {
  smsConsentLocked: false,
  smsConsentGiven: false,
  sendButtonDisabled: true,
  inputPlaceHolder: SMS_TEXTS.placeHolderDefault,
  inputError: false,
  consentError: false,
  smsSent: false,
  phoneNumber: '',
  buttonText: '',
  option: defaultOption
};

type ActionType =
  | 'SMS_CONSENT_CHECKBOX_CHANGE'
  | 'SMS_CONSENT_LOCKED'
  | 'CLEAR_INPUT_ERROR'
  | 'SET_INPUT_ERROR'
  | 'SMS_SENT'
  | 'SET_CONSENT_MISSING_ERROR'
  | 'SELECT_OPTION'
  | 'SET_DISABLE_BUTTON'
  | 'CLEAR_DISABLE_BUTTON'
  | 'SET_BUTTON_TEXT'
  | 'SET_PHONE_NUMBER'
  | 'SET_PHONE_PLACEHOLDER';

export interface InternalAction extends Action<ActionType> {
  payload?: any;
}

export const smsReducer = (state: State, action: InternalAction): State => {
  switch (action.type) {
    case 'SMS_CONSENT_LOCKED': {
      return {
        ...state,
        smsConsentLocked: true,
        consentError: false
      };
    }
    case 'SET_CONSENT_MISSING_ERROR': {
      return {
        ...state,
        consentError: true
      };
    }
    case 'SMS_CONSENT_CHECKBOX_CHANGE': {
      return {
        ...state,
        smsConsentGiven: action.payload || !state.smsConsentGiven // set checkbox directly via payload
      };
    }
    case 'CLEAR_INPUT_ERROR': {
      return {
        ...state,
        inputError: false,
        sendButtonDisabled: false
      };
    }
    case 'SET_INPUT_ERROR': {
      return {
        ...state,
        inputError: true
      };
    }
    case 'SMS_SENT': {
      return {
        ...state,
        smsSent: action.payload
      };
    }
    case 'SELECT_OPTION': {
      return {
        ...state,
        option: action.payload
      };
    }
    case 'SET_DISABLE_BUTTON': {
      return {
        ...state,
        sendButtonDisabled: true
      };
    }
    case 'CLEAR_DISABLE_BUTTON': {
      return {
        ...state,
        sendButtonDisabled: false
      };
    }
    case 'SET_PHONE_NUMBER': {
      return {
        ...state,
        phoneNumber: action.payload
      };
    }
    case 'SET_PHONE_PLACEHOLDER': {
      return {
        ...state,
        inputPlaceHolder: action.payload
      };
    }
    case 'SET_BUTTON_TEXT': {
      return {
        ...state,
        buttonText: action.payload
      };
    }
    default:
      throw new Error();
  }
};
