import React, { Dispatch, useEffect } from 'react';

import { useStateValue } from '../../hooks/useState';
import { useTranslation } from 'react-i18next';
import { InternalAction, State } from './smsReducer';
import { ConsentCheckBox } from '../consentCheckbox/ConsentCheckBox';
import { getItem } from '../../helper/storage';
import { postEvent } from 'src/api/postEvent';

interface Props {
  state: State;
  dispatch: Dispatch<InternalAction>;
}

export const SMSCheckBox = (props: Props) => {
  const { t } = useTranslation();
  const [{ uuid }]: any = useStateValue();

  let consentGiven = false;

  if (getItem(`${uuid}-consent`) == 'true') {
    consentGiven = true;
  }

  const checked =
    props.state.smsConsentLocked || props.state.smsConsentGiven || consentGiven;

  useEffect(() => {
    // user refreshed page, but selected checkbox before
    if (!props.state.smsConsentGiven && consentGiven) {
      props.dispatch({ type: 'SMS_CONSENT_CHECKBOX_CHANGE', payload: true });
    }
  }, [props.state, props.dispatch]);

  return (
    <>
      <ConsentCheckBox
        onChange={() => {
          postEvent({
            uuid,
            event: 'WEB.JUMP.ACTION.SMS.CONSENT',
            details: { checked: !checked }
          });
          props.dispatch({ type: 'SMS_CONSENT_CHECKBOX_CHANGE' });
        }}
        checked={checked}
        error={
          !props.state.smsConsentLocked &&
          !consentGiven &&
          props.state.consentError
        }
        errorText={t('We need your consent')}
        disabled={props.state.smsConsentLocked || consentGiven}
        text={t(
          "I consent to the use of my mobile number by Nect GmbH in accordance with the <a target={isLoadedFromApp ? undefined : '_blank'} rel={isLoadedFromApp ? undefined : 'noopener noreferrer'} href={getPrivacyLink()}>Privacy Policy</a>."
        )}
      />
    </>
  );
};
