import { delay } from 'q';

interface Props {
  fetch: () => Promise<any>;
  tries?: number;
  msBetween?: number;
}

export const gracefulFetch = async ({
  fetch,
  tries = 2,
  msBetween = 100
}: Props) => {
  let currentTry = 0;

  do {
    try {
      const json = await fetch();
      return json;
    } catch (e) {
      currentTry++;
      await delay(msBetween);
    }
  } while (currentTry < tries);

  throw new Error('fetch was unsuccessful');
};
