/* @ts-ignore */
import React from 'react';

import {
  getCountries,
  getCountryCallingCode
} from 'react-phone-number-input/input';

import en from 'react-phone-number-input/locale/en.json';
import de from 'react-phone-number-input/locale/de.json';
import es from 'react-phone-number-input/locale/es.json';
import pl from 'react-phone-number-input/locale/pl.json';
import { getLang } from '../../lang';

const langCode = getLang();
let smsTranslation = de;

if (langCode === 'es') {
  smsTranslation = es;
}

if (langCode === 'pl') {
  smsTranslation = pl;
}

if (langCode === 'en') {
  smsTranslation = en;
}

export const smsSelectOptions = getCountries()
  .filter((country: string) => !['AC', 'TA'].includes(country))
  .map((country: any, idx: number) => {
    return {
      value: country,
      langName: smsTranslation[country],
      searchData:
        smsTranslation[country] + ' +' + getCountryCallingCode(country),
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            width={24}
            style={{ paddingRight: 10 }}
            src={`${
              process.env.REACT_APP_CMS
            }/flags/${country.toLowerCase()}.svg`}
          />
          <span>{smsTranslation[country]}</span>
          <span>&nbsp;+{getCountryCallingCode(country)}</span>
        </div>
      )
    };
  })
  .sort((a: any, b: any) => {
    const al = a.langName;
    const bl = b.langName;

    if (a.value === 'DE') {
      return -1;
    }

    if (b.value === 'DE') {
      return 1;
    }

    if (al > bl) {
      return 1;
    }
  });
