import React from 'react';

import styles from './DescriptionBox.module.css';
import { useMedia } from 'react-use';

interface Props {
  title?: string;
  text?: string;
  textElement?: any;
  bottomElement?: any;
  helpIcon?: boolean;
}

export const DescriptionBox = ({
  textElement,
  bottomElement,
  text,
  title
}: Props) => {
  const isWide = useMedia('(min-width: 620px)');

  if (!isWide) {
    return null;
  }

  return (
    <div className={styles.descriptionBox}>
      <div style={{ height: 'auto' }}>
        {!!title && (
          <div>
            <h1 style={{ textAlign: 'left' }} className={styles.h1}>
              {title}
            </h1>
          </div>
        )}
        {text && <p>{text}</p>}
        {textElement && textElement}
        {bottomElement && bottomElement}
      </div>
    </div>
  );
};
