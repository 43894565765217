import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { extractUrlParam } from '../helper/extractUrlParam';
import { getItem, setItem } from '../helper/storage';

const acceptableLangs = ['en', 'de', 'es', 'pl'];

const getQueryStringLang = () => {
  let lang = extractUrlParam({
    url: window.location.search,
    type: 'lang'
  });

  if (!acceptableLangs.includes(lang)) {
    lang = getItem('lang');
  }

  if (acceptableLangs.includes(lang)) {
    setItem('lang', lang);
    return lang;
  }

  return false;
};

export const getLang = () => {
  const queryStringLang = getQueryStringLang();
  if (queryStringLang) {
    return queryStringLang;
  }
  const browserLang = (navigator.language || 'en').substr(0, 2).toLowerCase();

  if (acceptableLangs.includes(browserLang)) {
    return browserLang;
  }

  return 'de';
};

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: require('./en.json'),
  de: require('./de.json'),
  es: require('./es.json'),
  pl: require('./pl.json')
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLang(),
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
