import React from 'react';

import { ReviewBox } from '../ReviewBox/ReviewBox';
import { RatingBox } from '../RatingBox/RatingBox';
import { PartnerBox } from '../PartnerBox/PartnerBox';
import { useWideScreen } from '../../hooks/useWide';
import { useTranslation } from 'react-i18next';

export const ReviewCollection = () => {
  const isWideScreen = useWideScreen();
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div className={isWideScreen ? '' : 'blackBox'}>
        <div className={isWideScreen ? '' : 'blackBoxInner'}>
          <h1 style={{ textAlign: 'center' }}>{t("You're in good hands")}</h1>
          <h4>
            {t(
              'Nect is a proud member and partner of several official institutions.'
            )}
          </h4>

          <PartnerBox
            items={[
              {
                key: 'nvidia',
                resource: require('../../assets/nvidia.png')
              },
              {
                key: 'w1',
                resource: require('../../assets/w1-insurtech.jpg')
              },
              {
                key: 'insurlab',
                resource: require('../../assets/insurlab-germany.png')
              },
              {
                key: 'ifb',
                resource: require('../../assets/ifb.jpg')
              },
              {
                key: 'eu',
                resource: require('../../assets/eu.png')
              }
            ]}
          />
        </div>
      </div>

      <h4>
        {t(
          'Our clients are some of the biggest insurance and finance companies in Germany.'
        )}
      </h4>

      <PartnerBox
        noShadow
        items={[
          {
            key: 'r+v',
            resource: require('../../assets/ruv.png')
          },
          {
            key: 'wgv',
            resource: require('../../assets/wgv.svg')
          },
          {
            key: 'hek',
            resource: require('../../assets/hek.png')
          },
          {
            key: 'nordlb',
            resource: require('../../assets/nordlb.jpg')
          },
          {
            key: 'huk',
            resource: require('../../assets/huk.png')
          }
        ]}
      />

      <div className={isWideScreen ? '' : 'blackBox'}>
        <div className={isWideScreen ? '' : 'blackBoxInner'}>
          <h1>{t('Our users love Nect')}</h1>
          <h4>{t('Nect is the highest rated self identification app.')}</h4>

          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '25px 0',
              justifyContent: 'space-around'
            }}
          >
            <RatingBox type="ios" number={4.9} />
            <RatingBox type="android" number={4.8} />
          </div>
        </div>
      </div>

      <h4>{t('Check out what users say about the Nect app!')}</h4>

      <ReviewBox
        items={[
          {
            type: 'android',
            link:
              'https://play.google.com/store/apps/details?id=com.nect.app.prod&hl=de&reviewId=gp%3AAOqpTOGTN5wixHPXdN-hFe7viB-32EOn2ztAblwg_lNhHGYnECF146v_wXsp_cUcllA6a-G0stqlu-hIA4i-yg',
            text: 'einfach erklärt, super schnell. besser geht es nicht.',
            date: '24. Juli 2019'
          },
          {
            type: 'android',
            link:
              'https://play.google.com/store/apps/details?id=com.nect.app.prod&hl=de&reviewId=gp%3AAOqpTOF3LU7t7uviJu8_W2uzhJOhJcpEEp5bLbHXGT-4d-8NrsSkEgMSQtwbnbCRtWDrxtm6SB4uAwQ2umAkkw',
            text:
              'Super schnell und richtig gut gemacht. Alles einfach erklärt. Tolle App',
            date: '12. Juli 2019'
          },
          {
            type: 'android',
            link:
              'https://play.google.com/store/apps/details?id=com.nect.app.prod&hl=de&reviewId=gp%3AAOqpTOHo1IzV8bkjU2ZqL-p69swOGOJKKAWHa6hPzakIFCeQdEJpkunAViedf4Db_fsfZcC_IxwIkKKnLkA9DQ',
            text:
              'einfache und verständliche Handhabung, schnell und unkompliziert !',
            date: '29. Mai 2019'
          },
          {
            type: 'ios',
            text: 'Hat alles einwandfrei funktioniert.',
            date: '3. Juli 2019'
          },
          {
            type: 'ios',
            text:
              'Das ging wirklich einfach und problemlos. Man wird sehr gut durch die Anwendung geführt.',
            date: '24. Juli 2019'
          },
          {
            type: 'ios',
            text:
              'Die App arbeitet super. Auch die Art der Identifizierung ist besser als VideoIdent.',
            date: '24. Juli 2019'
          }
        ]}
      />
    </div>
  );
};
