import { generateAuthorizationHeader } from './generateAuthorizationHeader';
import { gracefulFetch } from './gracefulFetch';
import { postEvent } from './postEvent';

interface Props {
  uuid: UUID;
}

/**
 * Note: function throws if no browserRedirect is set
 */
export const getBrowserRedirectURI = async ({ uuid }: Props) => {
  let response: Response | null = null;

  if (!uuid) {
    throw new Error('No uuid found');
  }

  try {
    const headers = await generateAuthorizationHeader();

    response = await gracefulFetch({
      fetch: async () =>
        fetch(`${process.env.REACT_APP_API}/cases/${uuid}/browser_redirect`, {
          method: 'GET',
          headers
        })
    });
  } catch (error) {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getBrowserRedirectURI',
        error: error.message || error,
        stack: error.stack
      }
    });
  }

  if (response && response.ok) {
    const json = await response.json();

    if (
      json &&
      typeof json.browserRedirect === 'string' &&
      json.browserRedirect.length
    ) {
      return json.browserRedirect;
    }
  } else {
    postEvent({
      uuid,
      event: 'WEB.JUMP.ERROR',
      details: {
        info: 'getBrowserRedirectURI',
        status: response && response.status
      }
    });
  }

  throw new Error('browserRedirect not set');
};
