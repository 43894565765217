import { createClipboardData } from './createClipboardData';
import { copyToClipboard } from './copyToClipboard';
import { getDeviceType } from './device';
import { getServerEnvironment } from './getServerEnvironment';
import { getStoreLink } from './getStoreLink';
import { postEvent } from '../api/postEvent';

interface Props {
  uuid: string;
  responseURI: string;
}

export const attemptOpenStore = (props: Props) => {
  const { uuid, responseURI } = props;

  const clipboardData = createClipboardData({ uuid, responseURI });
  const success = copyToClipboard(clipboardData, uuid);

  if (!success) {
    postEvent({
      uuid,
      event: 'WEB.JUMP.OPENSTORE.COPYCLIPBOARD.FAIL',
      details: {
        responseURI,
        success,
        method: 'attemptOpenStore'
      }
    });
    return;
  }

  postEvent({
    uuid,
    event: 'WEB.JUMP.OPENSTORE.COPYCLIPBOARD.SUCCESS',
    details: {
      responseURI,
      info: 'opening store'
    }
  });

  const type = getDeviceType();
  const env = getServerEnvironment();
  const storeLink = getStoreLink({
    isMobile: true,
    env,
    type,
    additionalData: clipboardData
  });
  // window.open(storeLink, "_self");
  window.location.href = storeLink;
};
