import React, { useEffect, useState } from 'react';

import styles from './QRComponent.module.css';
import QRCode from 'qrcode.react';
import { useStateValue } from '../../hooks/useState';

import { useWideScreen } from '../../hooks/useWide';
import { getServerEnvironment } from '../../helper/getServerEnvironment';
import styled from 'styled-components';

import { Loader } from '../Loader/Loader';
import { getDirectLink } from 'src/helper/getDirectLink';

interface Props {
  style?: {};
  size?: number;
  boxProps?: {
    outerPadding?: number;
    outerBorderRadius?: number;
    innerPadding?: number;
    innerBorderRadius?: number;
  };
}

export const QRComponent = ({
  style,
  size,
  boxProps = {
    outerBorderRadius: 25,
    outerPadding: 15,
    innerPadding: 25,
    innerBorderRadius: 15
  }
}: Props) => {
  const isWide = useWideScreen();

  const {
    outerBorderRadius,
    outerPadding,
    innerPadding,
    innerBorderRadius
  } = boxProps;

  const [{ uuid, responseURI }]: any = useStateValue();

  const [url, setUrl] = useState('');

  useEffect(() => {
    if (uuid) {
      const env = getServerEnvironment();

      const endpoint = getDirectLink({ uuid, responseURI, env });

      setUrl(endpoint);
    }
  }, [uuid, responseURI]);

  if (!isWide) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          padding: outerPadding,
          borderRadius: outerBorderRadius,
          background: 'linear-gradient(0deg, #22EFCA, #08CAC1 100%, #22EFCA)'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: innerPadding,
            borderRadius: innerBorderRadius,
            background: 'white'
          }}
        >
          {url ? (
            <QRCode
              style={
                style || {
                  maxWidth: 250,
                  maxHeight: 250,
                  width: '22vw',
                  height: '22vw'
                }
              }
              value={url}
              size={size || 250}
              fgColor={'#2B2B2E'}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};
