import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #dddee0;
  border-radius: 50px;
  overflow: hidden;
`;

const Bar = styled.div`
  background: #0a0d1b;
  border-radius: 50px;
  height: 10px;
  transition: all 0.3s;
`;

export const ProgressBar = ({ value }: { value: number }) => {
  return (
    <Container>
      <Bar style={{ width: `${value}%` }} />
    </Container>
  );
};
