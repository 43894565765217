import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { useWideScreen } from '../../hooks/useWide';
import { theme } from '../../theme';
import { SMSStyled } from './SMS.styled';
import { SMSButton } from './SMSButton';
import { initialSmsState, smsReducer, SMS_TEXTS } from './smsReducer';
import { smsSelectOptions } from './smsSelectOptions';
import { smsSelectStyles } from './smsSelectStyles';
import { SMSCheckBox } from './SMSCheckBox';
import { postEvent } from 'src/api/postEvent';
import { useStateValue } from 'src/hooks/useState';

export const SMS = () => {
  const isWideScreen = useWideScreen();
  const { t } = useTranslation();
  const [{ uuid }] = useStateValue();

  const [state, dispatch] = useReducer(smsReducer, initialSmsState);

  if (!isWideScreen) {
    return null;
  }

  const onInputChange = (e: any) => {
    const value = e.currentTarget.value;
    let newText = value;

    if (value === '') {
      dispatch({ type: 'CLEAR_INPUT_ERROR' });
    }

    if (/^\d+$|\s/.test(value) || value === '') {
      const formattedValue = parsePhoneNumberFromString(
        `+${getCountryCallingCode(state.option.value)}${value}`
      );

      if (
        formattedValue &&
        (formattedValue.nationalNumber.length < 7 || !formattedValue.isValid())
      ) {
        dispatch({ type: 'SET_DISABLE_BUTTON' });
      } else {
        const newTextFormatted = formattedValue?.format('INTERNATIONAL');

        if (newTextFormatted && formattedValue) {
          newText = newTextFormatted.substr(
            formattedValue.countryCallingCode.length + 2,
            newTextFormatted.length + 1
          );

          dispatch({ type: 'CLEAR_INPUT_ERROR' });
        } else {
          dispatch({ type: 'SET_DISABLE_BUTTON' });
        }
      }

      dispatch({ type: 'SET_PHONE_NUMBER', payload: newText });
    }
  };

  return (
    <SMSStyled.Wrapper>
      <SMSStyled.FieldBoxWrapper style={{ width: 'auto' }}>
        <SMSStyled.FieldBox>
          <Select
            isSearchable={false}
            className="countrySelect"
            styles={smsSelectStyles}
            options={smsSelectOptions}
            value={{
              ...state.option,
              label: (
                <div
                  style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
                >
                  <img
                    width={20}
                    style={{ paddingRight: 8 }}
                    src={`${
                      process.env.REACT_APP_CMS
                    }/flags/${state.option.value.toLowerCase()}.svg`}
                  />
                  <span>+{getCountryCallingCode(state.option.value)}</span>
                </div>
              )
            }}
            onChange={(opt: any) => {
              try {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.SMS.PHONECOUNTRYCODE',
                  details: {
                    country: opt.value
                  }
                });
              } catch (error) {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ERROR',
                  details: {
                    error
                  }
                });
              }

              dispatch({ type: 'SELECT_OPTION', payload: opt });
            }}
          />
        </SMSStyled.FieldBox>
      </SMSStyled.FieldBoxWrapper>

      <SMSStyled.FieldBoxWrapper style={{ minWidth: 220, flexGrow: 1 }}>
        <span
          style={{
            position: 'absolute',
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            paddingLeft: 5,
            paddingRight: 5,
            zIndex: 20,
            textAlign: 'left',
            fontFamily: 'Poppins, sans-serif',
            transition:
              'transform 150ms cubic-bezier(0.4,0,0.2,1), opacity 150ms cubic-bezier(0.4,0,0.2,1)',
            display:
              state.phoneNumber || /\d/.test(state.inputPlaceHolder)
                ? 'inherit'
                : 'none',
            fontSize: 10,
            color: state.inputError
              ? theme.red
              : state.phoneNumber || /\d/.test(state.inputPlaceHolder)
              ? theme.primary
              : 'none',
            transform: 'translateY(-50%) translateX(15%)',
            backgroundColor: '#fff'
          }}
        >
          {t(SMS_TEXTS.placeHolderDefault)}
        </span>

        <SMSStyled.FieldBox
          style={{
            borderColor: state.inputError
              ? theme.red
              : /\d/.test(state.inputPlaceHolder)
              ? '#03AFA7'
              : 'inherit'
          }}
        >
          <input
            style={{
              flexGrow: 1,
              justifySelf: 'center',
              padding: 11.25,
              border: 'none',
              width: '100%',
              outline: 'none',
              direction: 'ltr'
            }}
            onFocus={() => {
              postEvent({
                uuid,
                event: 'WEB.JUMP.ACTION.SMS.PHONEINPUTFOCUS',
                details: {}
              });
              dispatch({
                type: 'SET_PHONE_PLACEHOLDER',
                payload: SMS_TEXTS.placeHolderTyped
              });
            }}
            onBlur={() => {
              if (!state.phoneNumber) {
                dispatch({
                  type: 'SET_PHONE_PLACEHOLDER',
                  payload: SMS_TEXTS.placeHolderDefault
                });
              } else {
                const value = parsePhoneNumberFromString(
                  `+${getCountryCallingCode(state.option.value)}${
                    state.phoneNumber
                  }`
                );

                if (
                  value &&
                  (value.nationalNumber.length < 7 || !value.isValid())
                ) {
                  dispatch({ type: 'SET_INPUT_ERROR' });
                } else {
                  dispatch({ type: 'CLEAR_INPUT_ERROR' });
                }

                if (!state.smsConsentLocked && !state.smsConsentGiven) {
                  dispatch({ type: 'SET_CONSENT_MISSING_ERROR' });
                }
              }
            }}
            placeholder={t(state.inputPlaceHolder)}
            value={state.phoneNumber}
            onInput={onInputChange}
            maxLength={14}
            type="tel"
          />
        </SMSStyled.FieldBox>

        {state.inputError && (
          <SMSStyled.BottomSpanError>
            {state.phoneNumber.length
              ? t('Please enter a valid phone number')
              : t('Phone number is required')}
          </SMSStyled.BottomSpanError>
        )}
      </SMSStyled.FieldBoxWrapper>

      <SMSStyled.FieldBoxWrapper>
        <SMSButton state={state} dispatch={dispatch} />
        <SMSStyled.BottomSpan>
          <SMSCheckBox state={state} dispatch={dispatch} />
        </SMSStyled.BottomSpan>
      </SMSStyled.FieldBoxWrapper>
    </SMSStyled.Wrapper>
  );
};
