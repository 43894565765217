import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Footer } from '../components/FooterNew/Footer';
import { useLocationPrepare } from '../hooks/useLocationPrepare';
import styles from '../components/StateBoxNew/StateBox.module.css';
import { LanguageSelector } from '../components/languageSelector/languageSelector';
import { LanguageSelectorWrapper } from '../components/languageSelector/languageSelector.styled';
import { supportedLanguages } from '../helper/supportedLangs';

const InnerContainer = styled.div`
  width: 300px;
  padding: 32px;
  background: #fafbfc;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h1`
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;

const Paragraph = styled.p`
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
`;

export const NoCaseScreen = () => {
  useLocationPrepare();
  const { t } = useTranslation();

  return (
    <div className="newPageRoot">
      <LanguageSelectorWrapper>
        <LanguageSelector langs={supportedLanguages} />
      </LanguageSelectorWrapper>
      <div className="newPageWrapper">
        <div className="newPageHeaderLogos">
          <img
            style={{ height: 50 }}
            src={require('../assets/nect-logo-black.svg')}
            alt="Nect"
          />
        </div>

        <h1 className="newPageHeaderTitle">{t('No case detected')}</h1>

        <p className="newPageHeaderSubtitle">
          {t(
            'This happens when you visit this page without starting an identification case from your service website.'
          )}
        </p>

        <div className={styles.stateBox}>
          <InnerContainer>
            <img
              style={{ height: 24 }}
              src={require('../assets/newJumpPage/note-text.svg')}
            />
            <Title>{t('How to get a new case?')}</Title>
            <Paragraph>
              {t('Follow the sign-up procedure on your service website.')}
            </Paragraph>
          </InnerContainer>
        </div>
      </div>

      <Footer />
    </div>
  );
};
