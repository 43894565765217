import React from 'react';

import styles from './PartnerBox.module.css';

interface Props {
  noShadow?: boolean;
  items: { key: string; resource: string }[];
}

const Item = ({
  resource,
  noShadow
}: {
  resource: string;
  noShadow: boolean;
}) => {
  return (
    <div className={noShadow ? styles.itemNoShadow : styles.item}>
      <img alt="Partner of Nect" className={styles.img} src={resource} />
    </div>
  );
};

export const PartnerBox = ({ items, noShadow = false }: Props) => (
  <div className={styles.row}>
    {items.map(item => (
      <Item key={item.key} noShadow={noShadow} resource={item.resource} />
    ))}
  </div>
);
