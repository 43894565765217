/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemBox } from './ItemBox';
import { useLocation } from '../../react-router-hooks';
import { routes } from '../../constants/constants';
import { useWideScreen } from '../../hooks/useWide';
import { useStateValue } from '../../hooks/useState';
import { setItem } from 'src/helper/storage';
import {
  CloseIcon,
  FeedbackBoxStyled,
  FeedbackOptionsStyled,
  FeedbackWrapper,
  HeaderStyled,
  Title
} from './feedbackBox.styled';

interface Props {
  onClose: () => void;
  onSubmitted: (newSubmitted: boolean) => void;
}

export const FeedbackBox = (props: Props) => {
  const { t } = useTranslation();
  const [{ caseStatus }]: any = useStateValue();
  const { location } = useLocation();
  const isWideScreen = useWideScreen();

  if (
    ['3514866227793627400'].includes(caseStatus && caseStatus.publicSPID) ||
    !isWideScreen ||
    location.pathname === routes.IFRAME
  ) {
    return null;
  }

  const close = () => {
    props.onClose();
    setItem('do-not-show-popup-again', 'true');
  };

  return (
    <FeedbackWrapper onClick={props.onClose}>
      <FeedbackBoxStyled onClick={(e: any) => e.stopPropagation()}>
        <HeaderStyled>
          <Title>{t('Leaving so soon?')}</Title>
          <CloseIcon onClick={close} />
        </HeaderStyled>

        <FeedbackOptionsStyled>
          <ItemBox onClose={props.onClose} onSubmitted={props.onSubmitted} />
        </FeedbackOptionsStyled>
      </FeedbackBoxStyled>
    </FeedbackWrapper>
  );
};
