import { useState, useEffect, useRef } from 'react';

export const useGetInstalledRelatedApps = () => {
  const [installedRelatedApps, setInstalledRelatedApps] = useState([]);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if ('getInstalledRelatedApps' in navigator) {
      (navigator as any)
        ?.getInstalledRelatedApps()
        ?.then(
          apps => componentIsMounted.current && setInstalledRelatedApps(apps)
        );
    }
  }, []);

  return installedRelatedApps;
};
