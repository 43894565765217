import React from 'react';

import styles from './FooterLinks.module.css';
import { Ellipse } from './Ellipse';
import { useTranslation } from 'react-i18next';
import {
  links,
  getPrivacyLink,
  getImprintLink,
  getLegalLink,
  getAboutLink
} from '../../../constants/constants';
import { useWideScreen } from '../../../hooks/useWide';
import { theme } from '../../../theme';
import { useStateValue } from '../../../hooks/useState';
import { humanReadableUuid } from '../../../helper/humanReadableUuid';
import { postEvent } from 'src/api/postEvent';

interface Link {
  text: string;
  href: string;
  onClick?: any;
  isMobile: boolean;
}

const Link = ({ text, href, isMobile, onClick }: Link) => (
  <a
    className={isMobile ? styles.aMobile : ''}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
    href={href}
    style={{ color: theme.black }}
  >
    {text}
  </a>
);

export const FooterLinks = () => {
  const isWideScreen = useWideScreen();
  const { t } = useTranslation();
  const [{ uuid = '' }] = useStateValue();

  return (
    <div className={styles.row}>
      <div className={styles.footerLinks}>
        <div className={isWideScreen ? styles.row : styles.mobileColumn}>
          <Link
            isMobile={!isWideScreen}
            text={t('Imprint')}
            href={getImprintLink()}
            onClick={() => {
              postEvent({
                uuid,
                event: isWideScreen
                  ? 'WEB.JUMP.ACTION.FOOTER.IMPRINT'
                  : 'WEB.JUMP.ACTION.MOBILE.MENU.IMPRINT',
                details: {}
              });
            }}
          />

          <Ellipse />

          <Link
            isMobile={!isWideScreen}
            text={t('Data Privacy')}
            href={getPrivacyLink()}
            onClick={() => {
              postEvent({
                uuid,
                event: isWideScreen
                  ? 'WEB.JUMP.ACTION.FOOTER.DATA_PRIVACY'
                  : 'WEB.JUMP.ACTION.MOBILE.MENU.DATA_PRIVACY',
                details: {}
              });
            }}
          />

          <Ellipse />

          <Link
            isMobile={!isWideScreen}
            text={t('Legal Information')}
            href={getLegalLink()}
            onClick={() => {
              postEvent({
                uuid,
                event: isWideScreen
                  ? 'WEB.JUMP.ACTION.FOOTER.LEGAL_INFO'
                  : 'WEB.JUMP.ACTION.MOBILE.MENU.LEGAL_INFO',
                details: {}
              });
            }}
          />

          <Ellipse />

          <Link
            isMobile={!isWideScreen}
            text={t('FAQ')}
            href={links.faq + '?uuid=' + uuid}
            onClick={() => {
              postEvent({
                uuid,
                event: isWideScreen
                  ? 'WEB.JUMP.ACTION.FOOTER.FAQ'
                  : 'WEB.JUMP.ACTION.MOBILE.MENU.FAQ',
                details: {}
              });
            }}
          />

          {uuid && (
            <>
              <Ellipse />

              <span
                style={{
                  color: theme.black,
                  fontWeight: 'bold',
                  fontSize: isWideScreen ? 'inherit' : '1.5rem',
                  lineHeight: isWideScreen ? 'inherit' : '3rem'
                }}
              >
                Case ID: {humanReadableUuid(uuid)}
              </span>
            </>
          )}
        </div>

        {!isWideScreen && (
          <div className={isWideScreen ? styles.row : styles.mobileColumn}>
            <Link
              isMobile={!isWideScreen}
              text={t('About Nect')}
              href={getAboutLink()}
              onClick={() => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.MOBILE.MENU.ABOUT_NECT',
                  details: {}
                });
              }}
            />

            <Ellipse />

            <Link
              isMobile={!isWideScreen}
              text={t('Contact')}
              href={links.contact}
              onClick={() => {
                postEvent({
                  uuid,
                  event: 'WEB.JUMP.ACTION.MOBILE.MENU.CONTACT',
                  details: {}
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
