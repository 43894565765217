import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { useLocation, useRouter } from '../react-router-hooks';
import { FaqStyled } from '../components/faq/Faq.styled';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  max-width: 605px;
`;

const Image = styled.img`
  max-width: 575px;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.h1`
  margin-bottom: 8px;
  margin-top: 0px;
  text-align: center;
`;

const Paragraph = styled.p`
  margin-top: 8px;
  margin-bottom: 40px;
  text-align: center;
  color: #0a0d1b;
`;

export const MaintenanceScreen = () => {
  const { t } = useTranslation();

  const { location } = useRouter();

  return (
    <div className="root">
      <Header
        isIFrame={location && location.state && location.state.isIFrame}
        noBadge={true}
      />
      <Wrapper id="main" className="main">
        <Container>
          <Image src={require('../assets/maintenance-illustration.svg')} />

          <Title>{t('Under Maintenance')}</Title>

          <Paragraph>
            {t(
              'We always want to become smarter for you.  Therefore we are currently running maintenance work on our system. We apologise for the delay and will be back for you soon.'
            )}
          </Paragraph>

          <FaqStyled.Box style={{ marginTop: 0 }}>
            <img src={require('../assets/clock.svg')} width="24" />

            <FaqStyled.BoxTitle>
              {t("We'll be back with you soon.")}
            </FaqStyled.BoxTitle>

            <FaqStyled.BoxMessage
              dangerouslySetInnerHTML={{
                __html: t(
                  'Please come back again later and <a href="javascript:window.location.reload();">reload</a> the page before you start a new try.'
                )
              }}
            />
          </FaqStyled.Box>
        </Container>
      </Wrapper>

      <Footer
        isIFrame={location && location.state && location.state.isIFrame}
      />
    </div>
  );
};
