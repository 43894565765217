import React from 'react';

import styles from './Menu.module.css';
import { FooterLinks } from '../Footer/FooterLinks/FooterLinks';
import { FooterSocial } from '../Footer/FooterSocial/FooterSocial';

interface Props {
  close: () => void;
}

export const Menu = ({ close }: Props) => {
  return (
    <div className={styles.fullscreen}>
      <header className={styles.header}>
        <img
          alt="Nect Logo"
          style={{ padding: '0 25px', height: 30, width: 34 }}
          src={require('../../assets/nect-logo-black.svg')}
        />
        <img
          alt="Close Menu"
          style={{ padding: '0 25px' }}
          onClick={close}
          src={require('../../assets/close-button.svg')}
        />
      </header>

      <div style={{ width: '100%', height: '100%', paddingTop: 55 }}>
        <FooterLinks />

        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <FooterSocial />
        </div>
      </div>
    </div>
  );
};
